<template>
<v-dialog
    @input="$emit('input', $event)"
    :value="value"
    max-width="720px">
    <v-card v-if="editUser">
    <v-progress-linear v-if="isSubmitting" indeterminate></v-progress-linear>
    <v-card-title>
        <span v-if="!isCreating">
          Edit {{ isSelf ? 'Profile' : 'User' }}
        </span>
        <span v-else>
          Create User
        </span>
    </v-card-title>
    <v-card-text v-if="isOffline">
      You cannot edit users while offline. Please try again after reconnecting to the internet.
    </v-card-text>
    <v-card-text v-else>
        <v-form ref="editUserForm" v-model="isEditUserFormValid">
          <v-expansion-panels
            v-model="expandedPanels"
            multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>User Info</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  label="First Name"
                  v-model="editUser.firstName"
                  :rules="[rules.required]"
                  class="mb-1"
                  dense
                  hide-details="auto"
                  outlined
                  required
                ></v-text-field>

                <v-text-field
                  label="Last Name"
                  v-model="editUser.lastName"
                  :rules="[rules.required]"
                  class="mb-1"
                  dense
                  hide-details="auto"
                  outlined
                  required
                ></v-text-field>

                <v-text-field
                  label="Email"
                  v-model="editUser.email"
                  :rules="[rules.required, rules.email]"
                  class="mb-1"
                  dense
                  hide-details="auto"
                  outlined
                  :disabled="!isCreating"
                  required
                ></v-text-field>

                <v-combobox
                  v-model="editUser.title"
                  ref="titleCombobox"
                  :items="[
                    { header: 'Choose one of the default options or type your own.' },
                    ...this.titleOptions,
                  ]"
                  class="mb-1"
                  hide-details="auto"
                  label="Title"
                  outlined
                  dense
                ></v-combobox>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <edit-user-permissions-expansion-panel
              v-if="user.can('manage users')"
              :edit-user="editUser"/>
  

            <v-expansion-panel v-if="isSelf">
              <v-expansion-panel-header>Authentication Details</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-btn
                  @click="openManageMfaDialog()"
                  color="primary"
                  outlined>
                  Manage Multi-Factor Authentication
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>Communication Preferences</v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ isSelf ? 'I want to' : 'This user should' }} receive a notification when:
                <v-checkbox
                v-model="editUser.communicationPreferences.newCycleBeginning"
                dense
                hide-details="auto"
                label="A new SchoolDog Walk Cycle is beginning"></v-checkbox>
                <v-checkbox
                  v-model="editUser.communicationPreferences.neighborhoodWatch"
                  dense
                  hide-details="auto">
                  <template v-slot:label>
                    <glossary-term text='A "Neighborhood Watch" notification is sent'/>
                  </template>
                </v-checkbox>
              <v-checkbox
                v-model="editUser.communicationPreferences.taskAssigned"
                dense
                hide-details="auto"
                :label="`A Task is proposed to ${isSelf ? 'me' : 'them'}`"></v-checkbox>
              <v-checkbox
                v-model="editUser.communicationPreferences.taskCompleted"
                dense
                hide-details="auto"
                :label="`A Task ${isSelf ? 'I' : 'they'} proposed is completed`"></v-checkbox>
              <v-checkbox
                v-model="editUser.communicationPreferences.taskDeclined"
                dense
                hide-details="auto"
                :label="`A Task ${isSelf ? 'I' : 'they'} proposed is declined`"></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Advanced Settings</v-expansion-panel-header>
              <v-expansion-panel-content>
                Please only adjust these settings when prompted by SchoolDog support.
                <v-checkbox
                  v-model="editUser.alwaysShowTakePicture"
                  dense
                  hide-details="auto"
                  label="Always show Take Picture button"></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="!isCreating && $can('manage users') && user.id !== editUser.id">
              <v-expansion-panel-header>
                <span class="error--text">Danger Zone</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-btn v-if="!isSelf" @click="isDeleteUserDialogVisible = true" text x-large color="error" width="100%">Permanently Delete User</v-btn>
                <v-dialog v-model="isDeleteUserDialogVisible" persistent max-width="528px">
                  <v-card>
                    <v-card-title>
                      Are you sure?
                    </v-card-title>
                    <v-card-subtitle>
                      Are you sure you want to delete this user ({{editUser.email}})? This cannot be undone.
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="!isSubmitting"
                        @click="isDeleteUserDialogVisible = false"
                        color="grey darken-1"
                        text
                      >No, Do Not Delete</v-btn>
                      <v-btn
                        @click="deleteUser()"
                        :disabled="isSubmitting"
                        color="primary"
                      >Yes, Delete</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
        <div v-if="isCreating" class="text-body-1 mt-2">
          NOTE: After submitting, the user will receive an email with instructions for setting up their account.
        </div>
    </v-card-text>
    <div class="mx-6 error--text" v-if="claimComplexityError">
      The permissions for this user are too complex. This is a rare case, and it can usually be solved by lowering the number of unique schools that are specified. For example, if you have more than 20 unique schools specified, consider using "Enable for all schools" instead of specifying each school individually or removing schools that are not necessary. Another option is to have separate user accounts for specific use cases. For help, please contact support.
    </div>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        @click="$emit('input', false)"
        color="grey darken-1"
        text
        >Cancel
        </v-btn>
        <v-btn
        v-if="!isOffline"
        @click="submitEditUser()"
        :disabled="isSubmitting"
        :loading="isSubmitting"
        color="primary"
        >Submit
        </v-btn>
    </v-card-actions>
    </v-card>
    <manage-mfa-dialog v-model="isManageMfaDialogVisible" />
</v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GlossaryTerm from '../common/GlossaryTerm.vue';
import EditUserPermissionsExpansionPanel from './EditUserPermissionsExpansionPanel.vue';
import ManageMfaDialog from './ManageMfaDialog.vue';

export default {
  name: 'EditUserDialog',
  components: {
    ManageMfaDialog,
    EditUserPermissionsExpansionPanel,
    GlossaryTerm,
  },
  props: {
    userToEdit: Object,
    isCreating: Boolean,
    value: Boolean,
  },
  data() {
    return {
      isDeleteUserDialogVisible: false,
      isEditUserFormValid: true,
      isSubmitting: false,
      editUser: null,
      rules: {
        required: value => Boolean(value) || 'This field is required',
        email: value => {
          const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return pattern.test(value) || 'Please enter a valid email';
        },
      },
      isManageMfaDialogVisible: false,
      expandedPanels: [],
      claimComplexityError: false,
    }
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'organizationSettings',
      'user',
    ]),
    titleOptions () {
      return [
        'Superintendent',
        'Deputy Superintendent',
        'Maintenance Director',
        'Safety Coordinator',
        'Curriculum Director',
        'Operations Director',
        'Chief of District Police',
        'Coordinator',
        'District Safety Team Member',
        'Principal',
        'Assistant Principal',
        'School Resource Officer',
        'School Safety Team Member',
      ]
    },
    isSelf () {
      return !this.isCreating && this.user.id === this.editUser.id
    },
    schoolItems() {
      if (!this.organizationSettings) {
        return [];
      }
      return this.organizationSettings.schools.map((school) => {
        return {
          text: school.name,
          value: school.id,
        }
      })
    },
  },
  methods: {
    ...mapActions('app', [
      'createUserFromObject',
      'showSuccess',
    ]),
    async deleteUser () {
      if (this.isSelf) {
        return false
      }
      if (this.$refs.editUserForm.validate() || this.isSubmitting) {
        this.isSubmitting = true
        try {
          await this.runFunction('archiveUser', {
            uid: this.editUser.id,
          })

          this.editUser = null;

          this.showSuccess('User Successfully Deleted');
          this.isDeleteUserDialogVisible = false
          this.$emit('refresh')
        } finally {
          this.isSubmitting = false
        }
      }
    },
    async submitEditUser () {
      this.claimComplexityError = false;
      if (this.$refs.titleCombobox) {
        this.$refs.titleCombobox.blur();
        await this.$nextTick();
      }
      if (this.$refs.editUserForm.validate() || this.isSubmitting) {
        if (this.checkIfClaimTooComplex()) {
          this.claimComplexityError = true;
          return;
        }
        this.isSubmitting = true
        try {
          if (this.isCreating) {
            const user = await this.createUserFromObject({ id: null, data: this.editUser });

            // Perform all of this server-side for security reasons
            await this.runFunction('generateNewUserAccount', {
              userData: user.toJson(),
            })

            this.showSuccess('User successfully created')
          } else {
            const user = await this.createUserFromObject({ id: this.editUser.id, data: this.editUser });

            await this.runFunction('editUser', {
              userId: user.id,
              updatedUserData: user.toJson(),
            });

            this.showSuccess('Changes saved successfully');
          }

          this.editUser = null;
          this.$emit('refresh')
        } finally {
          this.isSubmitting = false
        }
      }
    },
    async openManageMfaDialog () {
      this.isManageMfaDialogVisible = true
    },
    checkIfClaimTooComplex () {
      const permissions = this.editUser.permissions || {};
      const compressedPermissions = {
        'be proposed tasks': 'bpt',
        'collaborate on walks': 'cow',
        'create blank tasks': 'cbt',
        'create walks': 'cw',
        'manage cycles': 'mc',
        'manage district': 'md',
        'manage schools': 'ms',
        'manage walks': 'mw',
        'manage other walks': 'mow',
        'view completed walks': 'vcw',
        'view cycles': 'vc',
        'view data insights': 'vdi',
        'download data insights reports': 'ddi',
        'view observation leaderboard': 'vol',
        'create tasks from observations': 'cto',
        'manage rubrics': 'mr',
      };

      const compressedSchoolIds = {};
      let schoolNum = 1;

      // Collect and compress school IDs
      for (const permissionKey in permissions) {
        const permission = permissions[permissionKey];
        if (permission.hasPermission && !permission.always && permission.forSchools && permission.forSchools.length) {
          permission.forSchools.forEach((schoolId) => {
            if (!compressedSchoolIds[schoolId]) {
              compressedSchoolIds[schoolId] = schoolNum++;
            }
          });
        }
      }

      const claim = { s: compressedSchoolIds };

      // Compress permissions and map school IDs
      for (const permissionKey in permissions) {
        const permission = permissions[permissionKey];
        const compressedPermission = compressedPermissions[permissionKey];

        if (!compressedPermission) {
          // Skip permissions without Firestore security rules implications
          continue;
        }

        if (!permission.hasPermission) {
          claim[compressedPermission] = false;
        } else if (permission.always) {
          claim[compressedPermission] = true;
        } else if (permission.forSchools && permission.forSchools.length) {
          claim[compressedPermission] = permission.forSchools.map((schoolId) => compressedSchoolIds[schoolId]);
        } else {
          claim[compressedPermission] = false;
        }
      }
      return JSON.stringify(claim).length > 984;
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          if (this.$refs.editUserForm) {
            this.$refs.editUserForm.resetValidation();
          }
          if (this.isCreating) {
            this.editUser = {
              firstName: '',
              lastName: '',
              email: '',
              title: '',
              communicationPreferences: {
                newCycleBeginning: true,
                neighborhoodWatch: true,
                taskAssigned: true,
                taskCompleted: true,
                taskDeclined: true,
              },
              permissions: {},
              roleSchoolIds: [],
            };
            this.expandedPanels = [
              0,
              1,
              2,
            ]
          } else {
            this.editUser = JSON.parse(JSON.stringify(this.userToEdit));
          }
        } else {
          this.expandedPanels = []
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
