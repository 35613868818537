<template>
  <v-dialog v-model="dialog" max-width="528px">
    <v-card>
      <v-card-title class="headline">Install SchoolDog?</v-card-title>
      <v-card-text>
        For additional features like offline capabilities, download and use SchoolDog as an app (even on desktop!). SchoolDog is a Progressive Web App, meaning you do not need to utilize an external App Store. Simply click the "Install" button to get started.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">Close</v-btn>
        <v-btn color="primary darken-1" @click="installPwa">Install</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      deferredPrompt: null,
    };
  },
  mounted() {
    // this.beforeInstallPromptListener = (e) => {
    //   e.preventDefault();
    //   this.deferredPrompt = e;
    //   this.dialog = true;
    // };
    // window.addEventListener('beforeinstallprompt', this.beforeInstallPromptListener);
  },
  beforeDestroy() { // Use beforeUnmount for Vue 3
    // window.removeEventListener('beforeinstallprompt', this.beforeInstallPromptListener);
  },
  methods: {
    async installPwa() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        const choiceResult = await this.deferredPrompt.userChoice;
        if (choiceResult.outcome === 'accepted') {
        } else {
        }
        this.deferredPrompt = null;
      }
      this.dialog = false;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
