<!-- FileUpload.vue -->
<template>
  <v-col class="mb-1">
    <v-row>
      <v-file-input label="Upload File" @change="handleFileChange" />
    </v-row>
    <v-row class="float-right">
      <v-btn color="primary" @click="uploadFile" :disabled="!file">Upload</v-btn>
    </v-row>
  </v-col>
</template>

<script>
import { getStorage, ref as storageRef, uploadBytesResumable } from 'firebase/storage';

export default {
  props: {
    schoolId: null,
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    handleFileChange(event) {
      this.file = event;
    },
    async uploadFile() {
      if (this.file) {
        const storageReference = storageRef(getStorage(), `school/files/${this.schoolId}/${this.file.name}`);
        const uploadTask = uploadBytesResumable(storageReference, this.file);


        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // handle progress if needed
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => {
            console.error('Error uploading file: ', error);
            this.$emit('uploadFinish');
          },
          () => {
            // Handle successful upload
            console.log('File uploaded successfully');
          },
        );
        this.$emit('uploadStart');
        await uploadTask;
        this.$emit('uploadFinish');
        this.file = null;
      } else {
        console.error('No file selected');
      }
    },
  },
};
</script>
