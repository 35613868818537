import FirestoreModel from './FirestoreModel';

class WalkSettings extends FirestoreModel {
    static collectionName = 'settings';
    static allowedFields = [
      'requiredNumSchoolLedObservations',
    ]

    constructor(id, data) {
      super(id, data);
    }
}

export default WalkSettings;
