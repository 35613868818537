<template>
<v-menu offset-y :open-on-hover="$vuetify.breakpoint.mdAndUp">
    <template v-slot:activator="{ on, attrs }">
        <v-progress-circular
            v-bind="attrs"
            v-on="on"
            :size="48"
            :value="computedScore"
            :color="progressColor"
            rotate="-90"
            class="mr-1"
            >
            <div class="circle-text">{{ score }}</div>
        </v-progress-circular>
      </template>
        <v-list>
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title>{{ rubricName}}</v-list-item-title>
                <v-list-item-subtitle>{{ score }}/{{ maxScore }} ({{ computedScore }}%)</v-list-item-subtitle>
                <v-list-item-subtitle v-if="scoreThresholds">Color rating: {{ colorName }}</v-list-item-subtitle>
                <!-- Add more user details here -->
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
export default {
  props: {
    rubric: {
      type: Object,
    },
  },
  data() {
    return {
      showDetails: false,
      isShowingProperScore: false,
    };
  },
  computed: {
    computedScore() {
      if (!this.isShowingProperScore) return 0;
      return Math.round((this.score / this.maxScore) * 100);
    },
    progressColor() {
      if (!this.scoreThresholds) return 'primary darken-1';
      const { green, yellow, orange } = this.scoreThresholds;
      if (this.computedScore >= green) return 'green';
      else if (this.computedScore >= yellow) return '#C7A600';
      else if (this.computedScore >= orange) return 'orange';
      return 'red';
    },
    colorName() {
      return {
        green: 'Green',
        '#C7A600': 'Yellow',
        orange: 'Orange',
        red: 'Red',
      }[this.progressColor];
    },
    scoreThresholds() {
      if (!this.rubric.useScoreThresholds) return null;
      return this.rubric.scoreThresholds;
    },
    score() {
      return this.rubric.totalScore;
    },
    maxScore() {
      return this.rubric.maxScore;
    },
    rubricName() {
      return this.rubric.name;
    },
  },
  mounted () {
    setTimeout(() => {
      this.isShowingProperScore = true;
    }, 0);
  },
};
</script>
<style scoped>
.v-progress-circular {
  position: relative;
}
.v-progress-circular .long {
  animation-duration: 3s;
}
.circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.35rem;
  font-weight: bold;
}
</style>