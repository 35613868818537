<template>
  <div>
      <v-card v-for="(card, index) in cards" :class="{ 'mb-2': index !== cards.length - 1 }" :key="index">
        <v-container>
          <div style="display: flex">
            <div class="text-subtitle-1 mb-1" style="flex: 1">
              {{ card.title }}
            </div>
          </div>
          <div class="text-body-2 mb-1">
            <ul>
              <li v-for="(link, linkIndex) in card.links" :key="linkIndex">
                <div class="font-weight-medium">
                  <a :href="link.url" target="_blank">{{ link.text }}</a>
                </div>
              </li>
            </ul>
          </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('app', [
      'showGaBranding',
    ]),
    alCards () {
      return [
        {
          title: 'State Resources',
          links: [
            {
              text: '"Assurance of Compliance Form" for A Manual of State Laws and Regulations: School Safety and Discipline/Alabama Department of Education',
              url: 'https://www.alabamaachieves.org/wp-content/uploads/2023/09/StateSuperIn_Memos_20230914_FY23-3054-A-Manual-of-State-Laws-and-Regulations-School-Safety-and-Discipline-2023_V1.0.pdf',
            },
            {
              text: '"FY23 School Safety Update Memo"/Alabama Department of Education',
              url: 'https://www.alabamaachieves.org/wp-content/uploads/2023/08/StateSuperIn_Memos_2023818_FY23-3036-School-Safety-Update_V1.0.pdf',
            },
            {
              text: '"School Safety Plan Format"/Alabama Department of Education',
              url: 'https://www.alabamaachieves.org/wp-content/uploads/2021/04/School-System-and-Individual-School-Safety-Plan-FY07-with-Appendices.pdf  ',
            },
          ],
        },
      ]
    },
    gaCards () {
      return [
        {
          title: 'Georgia Department of Education',
          links: [
            {
              text: 'School Safety Assessment',
              url: 'https://www.gadoe.org/wholechild/Documents/School%20Safety%20Assessment.pdf',
            },
          ],
        },
        {
          title: 'Georgia Emergency Management and Homeland Security Agency',
          links: [
            {
              text: 'School Safety Resources',
              url: 'https://gema.georgia.gov/what-we-do/school-safety',
            },
          ],
        },
      ]
    },
    cards () {
      return [
        ...(this.showGaBranding ? this.gaCards : this.alCards),
        {
          title: 'Secret Service Reports',
          links: [
            {
              text: 'Averting Targeted School Violence',
              url: 'https://www.secretservice.gov/sites/default/files/reports/2021-03/USSS%20Averting%20Targeted%20School%20Violence.2021.03.pdf',
            },
            {
              text: 'A Toolkit for Strengthening K-12 Reporting Systems',
              url: 'https://www.secretservice.gov/sites/default/files/reports/2023-05/cisa-usss-k-12-bystander-reporting-toolkit-508_final_0.pdf',
            },
          ],
        },
        {
          title: 'Sandy Hook Commission Report',
          links: [
            {
              text: 'Final Report of the Sandy Hook Advisory Commission',
              url: 'https://portal.ct.gov/-/media/malloy-archive/sandy-hook-advisory-commission/shac_final_report_3-6-2015.pdf?sc_lang=en&hash=BDF55EC4ACE382E87941870AD9BF2A34',
            },
          ],
        },
      ].filter(card => card)
    },
  },
};
</script>
