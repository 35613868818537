<template>
  <v-dialog v-model="schooldogUpdateMajor" max-width="528px" persistent>
    <v-card>
      <v-card-title>
        New updates are available
      </v-card-title>
      <v-card-text>
        Please reload SchoolDog to get the latest features.
      </v-card-text>
      <v-card-actions v-if="!isOffline">
        <v-spacer></v-spacer>
        <v-btn @click="reload()" color="primary">Reload</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-card-text v-else>
        <v-alert type="error">
          You are offline. Please reconnect to reload.
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  computed: {
    ...mapState('app', [
      'schooldogUpdateMajor',
      'isOffline',
    ]),
    ...mapGetters('app', [
      'globalAppSettings',
    ]),
  },
  methods: {
    reload() {
      window.location.reload();
    },
  },
}
</script>
