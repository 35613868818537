export default {
  common: {
    add: 'Add',
    cancel: 'Cancel',
    description: 'Description',
    delete: 'Delete',
    title: 'Title',
    save: 'Save',
    faq: 'FAQ',
    contact: 'Contact Us',
    tos: 'Terms of Service',
    policy: 'Privacy Policy',
    close: 'Close',
  },
  board: {
    titlePlaceholder: 'Enter a title for this card',
    deleteDescription: 'Are you sure you want to delete this card?',
    editCard: 'Edit Card',
    deleteCard: 'Delete Card',
    state: {
      TODO: 'TO DO',
      INPROGRESS: 'INPROGRESS',
      TESTING: 'TESTING',
      DONE: 'DONE',
    },
  },
  chat: {
    online: 'Users Online ({count})',
    addChannel: 'Add Channel',
    channel: 'Channel | Channels',
    message: 'Message',
  },
  email: {
    compose: 'Compose Email',
    send: 'Send',
    subject: 'Subject',
    labels: 'Labels',
    emptyList: 'Empty email list',
    inbox: 'Inbox',
    sent: 'Sent',
    drafts: 'Drafts',
    starred: 'Starred',
    trash: 'Trash',
    work: 'Work',
    invoice: 'Invoice',
  },
  rubric: {
    addRubric: 'Add Rubric',
    name: 'Name',
    details: '(Details)',
    formTitle: 'Rubric Details',
    title: 'Title',
    body: 'Body (Optional)',
    edit: 'Edit',
    enabled: 'Enabled',
    preview: '(Preview)',
    districtWalksOnly: 'Only link to district-led SchoolDog Walks',
    skipByDefault: 'Skip this rubric by default during SchoolDog Walks',
    question: 'Question',
    score: 'Score',
    text: 'Description',
    answers: 'Answers',
    answer: 'Answer',
    bulletPoints: 'Bullet Points',
    save: 'Save changes',
    updateError: 'An error occurred while saving your changes.',
    deleteError: 'An error occurred while trying to delete the rubric.',
    addQuestion: 'Add question',
    addAnswer: 'Add answer',
    addBullet: 'Add bullet',
    expectedConcerns: 'Minimum # of concerns to auto-answer',
    expectedPraises: 'Minimum # of praises to auto-answer',
    thresholdExplanation: 'These thresholds will automatically select the correct answer during a walk when the number of praises/concerns meets or exceeds the configured minimums.',
  },
  school: {
    addNewLinks: 'Add and Save New Links',
    address: 'Address',
    street: 'Street',
    street2: 'Street 2 (Optional)',
    city: 'City',
    state: 'State',
    zip: 'Zip Code',
    email: 'Email',
    files: 'Files',
    links: 'Links',
    loadingError: 'There was an error loading the school details.',
    name: 'Name',
    phone: 'Phone',
    fax: 'Fax',
    school: 'School',
    schoolOfficeNumber: 'Office Phone',
    uploadError: 'There was an error saving your changes.',
    website: 'Website',
    noAddress: 'No Address',
    noEmail: 'No Email',
    noFiles: 'Upload New Files Below',
    noPhone: 'No Phone Number',
    noFax: 'No Fax',
    noSchoolOfficeNumber: 'No School Office Phone Number',
    noWebsite: 'No Website',
    noPrincipalName: 'No Principal',
    noPrincipalOfficePhone: 'No Principal Office Phone',
    noPrincipalEmail: 'No Principal Email',
    principalName: 'Principal',
    principalOfficePhone: 'Principal Office Phone',
    principalEmail: 'Principal Email',
    schoolName: 'School Name',
    noSchoolName: 'No School Name',
  },
  todo: {
    addTask: 'Add Task',
    tasks: 'Tasks',
    completed: 'Completed',
    labels: 'Labels',
  },
  dashboard: {
    activity: 'Activity',
    weeklySales: 'Weekly Sales',
    currentCycle: 'Current Cycle',
    walkCompleted: 'Walk Completed',
    walkNotComplete: 'Walk Not Completed This Cycle',
    sales: 'Sales',
    recentOrders: 'Recent Orders',
    sources: 'Traffic Sources',
    lastweek: 'vs last week',
    orders: 'Orders',
    customers: 'Customers',
    percentComplete: 'Percent Complete',
    tickets: 'Support Tickets',
    viewReport: 'View Report',
  },
  usermenu: {
    profile: 'Profile',
    signin: 'Sign In',
    dashboard: 'Dashboard',
    signout: 'Sign Out',
  },
  error: {
    notfound: 'Page Not Found',
    other: 'An Error Ocurred',
  },
  check: {
    title: 'Set New Password',
    backtosign: 'Back to Sign In',
    newpassword: 'New Password',
    button: 'Set new password and Sign in',
    error: 'The action link is invalid',
    verifylink: 'Verifying link...',
    verifyemail: 'Verifying email address...',
    emailverified: 'Email verified! Redirecting...',
  },
  forgot: {
    title: 'Forgot Password?',
    subtitle: 'Enter your account email address and we will send you a link to reset your password.',
    email: 'Email',
    button: 'Request Password Reset',
    backtosign: 'Back to Sign In',
  },
  login: {
    title: 'Sign In',
    email: 'Email',
    password: 'Password',
    button: 'Sign In',
    orsign: 'Or sign in with',
    forgot: 'Forgot password?',
    noaccount: 'Don\'t have an account?',
    create: 'Create one here',
    error: 'The email / password combination is invalid',
  },
  register: {
    title: 'Create Account',
    name: 'Full name',
    email: 'Email',
    password: 'Password',
    button: 'Create Account',
    orsign: 'Or sign up with',
    agree: 'By signing up, you agree to the',
    account: 'Already have an account?',
    signin: 'Sign In',
  },
  utility: {
    maintenance: 'In Maintenance',
  },
  faq: {
    call: 'Have other questions? Please reach out ',
  },
  ecommerce: {
    products: 'Products',
    filters: 'Filters',
    collections: 'Collections',
    priceRange: 'Price Range',
    customerReviews: 'Customer Reviews',
    up: 'and up',
    brand: 'Brand',
    search: 'Search for product',
    results: 'Results ( {0} of {1} )',
    orders: 'Orders',
    shipping: 'Shipping',
    freeShipping: 'Free Shipping',
    inStock: 'In Stock',
    quantity: 'Quantity',
    addToCart: 'Add To Cart',
    buyNow: 'Buy Now',
    price: 'Price',
    about: 'About this item',
    description: 'Description',
    reviews: 'Reviews',
    details: 'Product Details',
    cart: 'Cart',
    summary: 'Order Summary',
    total: 'Total',
    discount: 'Discount',
    subtotal: 'Subtotal',
    continue: 'Continue Shopping',
    checkout: 'Checkout',
  },
  menu: {
    search: 'Search',
    dashboard: 'Dashboard',
    schools: 'Schools',
    cycles: 'Cycles',
    logout: 'Sign Out',
    profile: 'Profile',
    pages: 'Pages',
    rubrics: 'Rubrics',
    others: 'Others',
    email: 'Email',
    chat: 'Chat',
    todo: 'To Do',
    board: 'Task Board',
    users: 'Users',
    usersList: 'List',
    usersEdit: 'Edit',
    ecommerce: 'Ecommerce',
    ecommerceList: 'Products',
    ecommerceProductDetails: 'Product Details',
    ecommerceOrders: 'Orders',
    ecommerceCart: 'Cart',
    auth: 'Auth Pages',
    authLogin: 'Signin / Login',
    authRegister: 'Signup / Register',
    authVerify: 'Verify email',
    authForgot: 'Forgot Password',
    authReset: 'Reset password',
    errorPages: 'Error Pages',
    errorNotFound: 'Not Found / 404',
    errorUnexpected: 'Unexpected / 500',
    utilityPages: 'Utility Pages',
    utilityMaintenance: 'Maintenance',
    utilitySoon: 'Coming Soon',
    utilityHelp: 'FAQs / Help',
    levels: 'Menu Levels',
    disabled: 'Menu Disabled',
    docs: 'Documentation',
    feedback: 'Feedback',
    support: 'Support',
    lunchCountPage: 'Lunch Count',
    dismissalPage: 'Dismissal',
    getHelp: 'Get Help',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    contactSupport: 'Contact Support',
    administrator: 'Administrator',
    reports: 'Reports',
    messaging: 'Messaging',
    admin: {
      schooldog: 'Dev Settings',
      manageUsers: 'Manage Users',
    },
    schoolDogWalks: 'SchoolDog Walks',
    resourceLinks: 'Resource Links',
    legal: 'Legal',
    legalSection: {
      privacyPolicy: 'Privacy Policy',
      termsOfUse: 'Terms and Conditions',
    },
  },
  // Vuetify components translations
  $vuetify: {
    badge: 'Badge',
    close: 'Close',
    dataIterator: {
      noResultsText: 'No matching records found',
      loadingText: 'Loading items...',
    },
    dataTable: {
      itemsPerPageText: 'Rows per page:',
      ariaLabel: {
        sortDescending: 'Sorted descending.',
        sortAscending: 'Sorted ascending.',
        sortNone: 'Not sorted.',
        activateNone: 'Activate to remove sorting.',
        activateDescending: 'Activate to sort descending.',
        activateAscending: 'Activate to sort ascending.',
      },
      sortBy: 'Sort by',
    },
    dataFooter: {
      itemsPerPageText: 'Items per page:',
      itemsPerPageAll: 'All',
      nextPage: 'Next page',
      prevPage: 'Previous page',
      firstPage: 'First page',
      lastPage: 'Last page',
      pageText: '{0}-{1} of {2}',
    },
    datePicker: {
      itemsSelected: '{0} selected',
      nextMonthAriaLabel: 'Next month',
      nextYearAriaLabel: 'Next year',
      prevMonthAriaLabel: 'Previous month',
      prevYearAriaLabel: 'Previous year',
    },
    noDataText: 'No data available',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
      ariaLabel: {
        delimiter: 'Carousel slide {0} of {1}',
      },
    },
    calendar: {
      moreEvents: '{0} more',
    },
    fileInput: {
      counter: '{0} files',
      counterSize: '{0} files ({1} in total)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Pagination Navigation',
        next: 'Next page',
        previous: 'Previous page',
        page: 'Goto Page {0}',
        currentPage: 'Current Page, Page {0}',
      },
    },
  },
}
