<template>
  <div class="d-flex flex-column flex-grow-1" v-if="files && !isOffline">
    <v-progress-linear v-if="loading" indeterminate color="primary"/>
    <v-card>
      <v-card-title class="flex-grow-1 flex-row d-flex">
        {{$t('school.files')}}
      </v-card-title>
      <v-card-text>
        <v-list v-if="files.length > 0">
          <v-list-item-group>
            <v-list-item v-for="file in files" :key="file.id">
              <v-list-item-content>
                <a :href="file.downloadUrl" :download="file.name" target="_blank">
                  <v-list-item-title>
                      {{ file.name }}
                  </v-list-item-title>
                </a>
              </v-list-item-content>
              <v-list-item-icon v-if="canEdit">
                <v-btn class="float-right" icon color="primary" @click="deleteFile(file)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-card-subtitle v-else>
          {{$t('school.noFiles')}}
        </v-card-subtitle>
        <school-file-upload v-if="canEdit" :schoolId="schoolId" @uploadStart="uploadStart" @uploadFinish="uploadFinish"/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref as storageRef, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { storage } from '@/firebase-setup.js'; // Import your Firebase configuration
import SchoolFileUpload from './SchoolFileUpload.vue';
import { mapState } from 'vuex';

export default {
  components: { SchoolFileUpload },
  props: {
    schoolId: null,
  },
  data() {
    return {
      files: [],
      loading: false,
    };
  },
  async mounted() {
    // Fetch files from Firebase Storage and update the component data
    await this.fetchFiles();
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'user',
    ]),
    canEdit() {
      return !this.isOffline && this.user.canAtSchool('manage schools', this.schoolId);
    },
  },
  methods: {
    async fetchFiles() {
      try {
        const filesRef = storageRef(storage, `school/files/${this.schoolId}`); // Replace with your actual storage path

        // Get the list of items (files) in the storage directory
        this.loading = true;
        const items = await listAll(filesRef);
        this.loading = false;
        // Process each item to get download URL
        const filesPromises = items.items.map(async (item) => {
          const downloadUrl = await getDownloadURL(item);
          return { id: item.fullPath, name: item.name, downloadUrl };
        });

        // Wait for all promises to resolve
        this.files = await Promise.all(filesPromises);
      } catch (error) {
        console.error('Error fetching files:', error.message);
      }
    },
    async deleteFile(file) {
      const fileRef = storageRef(storage, `school/files/${this.schoolId}/${file.name}`); // Replace with your actual storage path
      this.loading = true;
      await deleteObject(fileRef);
      this.fetchFiles();
    },
    uploadStart() {
      this.loading = true;
    },
    uploadFinish() {
      this.fetchFiles();
    },
  },
};
</script>
