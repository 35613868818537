// ImageUploadService.js
import { getStorage, ref, uploadBytesResumable, uploadBytes, getDownloadURL } from 'firebase/storage';
// db.js
import { openDB } from 'idb';

async function initDB() {
  const db = await openDB('ImageUploadDB', 1, {
    upgrade(db) {
      db.createObjectStore('images', { keyPath: 'storagePath' });
    },
  });
  return db;
}
async function storeImage(storagePath, file) {


  // Use FileReader to read the file data immediately inside the transaction
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const db = await initDB();
        const tx = db.transaction('images', 'readwrite');
        const store = tx.objectStore('images');
        const arrayBuffer = reader.result;
        await store.put({ storagePath, file: arrayBuffer, metadata: { type: file.type, name: file.name, lastModified: file.lastModified } });
        await tx.done;
        resolve();
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsArrayBuffer(file);
  });
}

async function retrieveImages() {
  const db = await initDB();
  const tx = db.transaction('images', 'readonly');
  const images = await tx.objectStore('images').getAll();
  await tx.done;
  // Convert ArrayBuffer back to File
  return images.map(img => ({
    storagePath: img.storagePath,
    file: new File([
      img.file,
    ], img.metadata?.name, { type: img.metadata?.type, lastModified: img.metadata?.lastModified }),
  }));
}

async function deleteImage(storagePath) {
  const db = await initDB();
  const tx = db.transaction('images', 'readwrite');
  await tx.objectStore('images').delete(storagePath);
  await tx.done;
}

class ImageUploadService {

  beginFileUpload(storagePath, file) {
    console.log('beginFileUpload', storagePath, file)
    // Store the image to IndexedDB first
    const storage = getStorage()
    const storageRef = ref(storage, storagePath);
    storeImage(storagePath, file).then(async () => {
      const uploadTask = uploadBytesResumable(storageRef, file, {
        customMetadata: {
          fileName: file.name,
          timestamp: new Date().toString(),
        },
      });


      uploadTask.on('state_changed', 
        (snapshot) => {
          // Handle progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // if (progress) {
          // }
        },
        (error) => {
          console.error('Upload failed', error);
        }, 
        () => {
          // Delete the image from IndexedDB on successful upload
          deleteImage(storagePath);
        },
      );
    });
    return storageRef
  }

  alertUserOfPendingUploads() {
    retrieveImages().then(images => {
      if (images.length > 0) {
        let str = `You have ${images.length} pending uploads:\n`;
        images.forEach(({ file, storagePath }) => {
          const fileLength = file.size;
          str += storagePath + ' - ' + fileLength + ' bytes\n';
        });
        alert(str);
      } else {
        alert('No pending uploads')
      }
    });
  }

  resumeFileUploads() {
    retrieveImages().then(images => {
      images.forEach(({ storagePath, file }) => {
        console.log('resuming upload', storagePath)
        const storage = getStorage()
        const storageRef = ref(storage, storagePath);
        getDownloadURL(storageRef).then((url) => {
          // File exists, delete from IndexedDB
          deleteImage(storagePath);
        }).catch((error) => {
          // File does not exist, attempt to re-upload
          if (error.code === 'storage/object-not-found') {
            this.beginFileUpload(storagePath, file);
          } else {
            // alert('Unexpected error for ' + storagePath + ': ' + error.code)
          }
        });
      });
    });
  }
}

export default new ImageUploadService();
