<template>
<div class="d-flex" style="width: 100%; max-width: 100%;">
  <div class="flex-grow-1" style="width: 100%; max-width: 100%;">
    <v-toolbar
      color="#ffffff"
      style="border-bottom: 1px solid #1b7476;"
      width="100%">

      <v-checkbox v-model="filters.awaitingMyAction" class="mr-2" hide-details="auto" label="Awaiting my action" />

      <div class="flex-grow-1" />

      <!-- Date Range Picker -->
      <v-menu
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="isShowingDateRangeMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="dateRangeText"
            label="Date Range"
            class="mr-2"
            prepend-inner-icon="mdi-calendar"
            readonly
            hide-details="auto"
            outlined
            dense
            style="width: 240px; max-width: 240px;"
            v-on="on"
            clearable
            @click:clear="filters.dateRange = []"
          ></v-text-field>
        </template>
        <v-date-picker
          range
          v-model="filters.dateRange"
          @input="datesSelected"
        ></v-date-picker>
      </v-menu>


      <!-- Multi-select for Schools -->
      <v-select
        v-if="$vuetify.breakpoint.mdAndUp" 
        v-model="filters.schoolIds"
        hide-details="auto"
        :items="schoolItems"
        :menu-props="{ offsetY: true }"
        outlined
        dense
        label="Schools"
        style="max-width: 256px;"
        multiple>
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0">
            {{ filters.schoolIds.length > 1 ? `${filters.schoolIds.length} Schools Selected` : item.text }}
          </span>
        </template>
      </v-select>

      <!-- Filter Button with Dropdown Menu -->
      <v-menu :close-on-content-click="false" offset-y max-width="480px" min-width="320px">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" color="grey darken-1" class="ml-1">
            Filters
            <v-icon>mdi-filter-variant</v-icon>
            <v-badge v-if="numberOfActiveMenuFilters" color="primary" :content="numberOfActiveMenuFilters" />
          </v-btn>
        </template>
        <v-list class="pb-2">
          <div class="pt-2 px-2 text-h6">
            Filters
          </div>
          <v-list-item v-if="!$vuetify.breakpoint.mdAndUp">
            <v-menu
              v-model="isShowingDateRangeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="dateRangeText"
                  label="Date Range"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details="auto"
                  outlined
                  dense
                  v-on="on"
                  clearable
                  @click:clear="filters.dateRange = []"
                ></v-text-field>
              </template>
              <v-date-picker
                range
                v-model="filters.dateRange"
              ></v-date-picker>
            </v-menu>
          </v-list-item>
          <v-list-item>
            <v-select
              v-model="filters.walkTypes"
              hide-details="auto"
              :items="[
                { text: 'School-Led', value: 'school' },
                { text: 'District-Led', value: 'district' },
                { text: 'Partner-Led', value: 'partner' },
              ]"
              :menu-props="{ offsetY: true }"
              outlined
              dense
              label="Walk Types"
              multiple />
          </v-list-item>
          <v-list-item>
            <users-select
              v-model="filters.userIds"
              hide-details="auto"
              outlined
              show-self
              dense
              label="Users"
              multiple />
          </v-list-item>
          <v-list-item v-if="!$vuetify.breakpoint.mdAndUp">
            <v-select v-model="filters.schoolIds" hide-details="auto" :items="schoolItems" outlined dense label="Schools" multiple :menu-props="{ offsetY: true }">
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">
                  {{ filters.schoolIds.length > 1 ? `${filters.schoolIds.length} Schools Selected` : item.text }}
                </span>
              </template>
            </v-select>
          </v-list-item>
          <v-list-item>
            <v-checkbox v-model="filters.showArchived" hide-details="auto" label="Show archived" dense></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-card rounded="0">
      <v-tabs v-if="!isOffline" v-model="currentTab" :prev-icon="null">
        <v-tab>
          In Progress
          <v-badge :color="filteredInProgressWalks.length ? 'primary' : 'grey darken-1'" :content="filteredInProgressWalks.length || '0'" inline offset-x="20" offset-y="20" />
        </v-tab>
        <v-tab>
          Completed
          <v-badge :color="filteredCompletedWalks.length ? 'primary' : 'grey darken-1'" :content="filteredCompletedWalks.length || '0'" inline offset-x="20" offset-y="20" />
        </v-tab>
      </v-tabs>
    </v-card>

    <div class="d-flex flex-column flex-grow-1 px-4 pb-4 pt-2">
      <div v-if="!filteredWalks.length || isOffline" class="d-flex justify-center text-center my-6">
        <div v-if="isOffline" class="d-flex justify-center">
          <div style="max-width: 75%" class="text-subtitle-1 mb-2">You are using SchoolDog in offline mode. Some features like viewing images on observation entries will not work, as they require internet connection. However, you can still record entries on SchoolDog Walks, and they will automatically save when you reconnect.</div>
        </div>
        <div v-else-if="!haveWalksLoaded">
          <v-progress-circular indeterminate color="primary" />
        </div>
      </div>

      <div v-if="0 === currentTab" style="width: 100%">
        <div class="row px-2 pb-2" v-if="filteredInProgressWalks.length">
          <div
            v-for="walk in filteredInProgressWalks.slice(0, numInProgressToShow)"
            :class="{
              'col-6': $vuetify.breakpoint.mdAndUp,
              'col-12': !$vuetify.breakpoint.mdAndUp,
            }"
            :key="walk.id"
            class="pa-1">
            <walk-summary-card
              class="fill-height"
              @archive="archiveWalk(walk)"
              @delete="deleteWalk(walk)"
              @enter="$router.push(`/walks/walk/${walk.id}/${walk.status}`)"
              @set-in-progress="setWalkInProgress(walk)"
              @unarchive="unarchiveWalk(walk)"
              @click="$router.push(`/walks/walk/${walk.id}/${walk.status}`)"
              :organization-settings="organizationSettings"
              :walk="walk"></walk-summary-card>
          </div>
          <div v-if="hasMoreInProgress" class="d-flex fill-width justify-center"  style="width: 100%">
            <v-btn
                v-if="hasMoreInProgress"
                @click="fetchMoreInProgress()"
                class="mt-2"
                color="primary"
                text
                >
                Load More
            </v-btn>
          </div>
        </div>
        <div v-else-if="haveWalksLoaded" style="max-width: 100%; width: 100%;" class="d-flex flex-column align-center">
          <div style="width: 500px; max-width: 100%" class="text-subtitle-1 mb-2 text-center">There are no SchoolDog Walks that meet your criteria. Try refining your search or starting a new SchoolDog Walk.</div>
          <img style="width: 360px; max-width: 90%" src="@/assets/images/empty_folder.png" />
        </div>
      </div>

      <div v-if="!isOffline && currentTab === 1">
        <div v-if="filteredCompletedWalks.length" class="row px-2 pb-2">
          <div
            v-for="walk in filteredCompletedWalks.slice(0, numCompletedToShow)"
            :class="{
              'col-6': $vuetify.breakpoint.mdAndUp,
              'col-12': !$vuetify.breakpoint.mdAndUp,
            }"
            :key="walk.id"
            class="pa-1">
            <walk-summary-card
              class="fill-height"
              @archive="archiveWalk(walk)"
              @delete="deleteWalk(walk)"
              @enter="$router.push(`/walks/walk/${walk.id}/${walk.status}`)"
              @set-in-progress="setWalkInProgress(walk)"
              @unarchive="unarchiveWalk(walk)"
              @click="$router.push(`/walks/walk/${walk.id}/${walk.status}`)"
              :organization-settings="organizationSettings"
              :walk="walk"></walk-summary-card>
          </div>
          <div v-if="hasMoreCompleted" class="d-flex full-width justify-center" style="width: 100%">
            <v-btn
                v-if="hasMoreCompleted"
                @click="fetchMoreCompleted()"
                class="mt-2"
                color="primary"
                large
                text
                >
                Load More
                <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-else-if="haveWalksLoaded" style="max-width: 100%; width: 100%;" class="d-flex flex-column align-center">
          <div style="width: 500px; max-width: 100%" class="text-subtitle-1 mb-2 text-center">There are no SchoolDog Walks that meet your criteria. Try refining your search or starting a new SchoolDog Walk.</div>
          <img style="width: 360px; max-width: 90%" src="@/assets/images/empty_folder.png" />
        </div>
      </div>

      <v-fab-transition v-if="user.canAtAnySchool('create walks')">
        <v-btn
          @click="isNewWalkDialogVisible = true"
          bottom
          color="primary"
          dark
          elevation="12"
          right
          fixed
          rounded
          x-large
        >
          Start new SchoolDog Walk
        </v-btn>
      </v-fab-transition>

      <!-- PADDING FOR FABS -->
      <div style="height: 64px" />

      <create-walk-dialog v-model="isNewWalkDialogVisible"/>

      <v-dialog v-model="isCompletedWalkDialogVisible" max-width="528px">
        <v-card>
          <v-card-title>This SchoolDog Walk has been completed</v-card-title>
          <v-card-subtitle> You can no longer make changes, but you can continue to access the data. </v-card-subtitle>
          <v-card-text>
            <div class="overline">
              View Details
            </div>
            <div>
              <v-btn color="primary" @click="$router.push(`/walks/walk/${completedWalkDialogWalk.id}/${completedWalkDialogWalk.status}`)" text>
                <v-icon>mdi-file-eye-outline</v-icon><span style="padding: 12px">View SchoolDog Walk Details</span>
              </v-btn>
            </div>
            <div class="overline mt-1">
              Download Reports
            </div>
            <div>
              <v-btn @click="generateReport('summative')" color="primary" text>
                <v-icon>mdi-file-pdf-box</v-icon><span style="padding: 12px">Full Summative Report</span>
              </v-btn>
            </div>
            <div>
              <v-btn @click="generateReport('observations')" color="primary" text>
                <v-icon>mdi-file-pdf-box</v-icon><span style="padding: 12px">Observations Report</span>
              </v-btn>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="isCompletedWalkDialogVisible = false" color="grey darken-1" text> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="isGeneratingReport" persistent max-width="528px">
        <v-card>
          <v-card-title>
            Generating Report...
          </v-card-title>
          <v-card-text>
            <div>
              This might take a few seconds.
            </div>
            <div class="row justify-center py-4">
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import WalkSummaryCard from '../../components/walks/WalkSummaryCard.vue';
import CheckPermissionMixin from '../../mixins/CheckPermissionMixin';
import CreateWalkDialog from '../../components/walks/CreateWalkDialog.vue';
import Walk from '../../models/Walk';
import UsersSelect from '../../components/common/UsersSelect.vue';

export default {
  name: 'ViewWalksPage',
  mixins: [
    CheckPermissionMixin,
  ],
  canAtAnySchool: 'create walks|collaborate on walks|manage walks|manage other walks',
  components: {
    WalkSummaryCard,
    CreateWalkDialog,
    UsersSelect,
  },
  data() {
    return {
      completedWalkDialogWalk: null,
      statusLabels: {
        review: 'In Review',
        inprogress: 'In Progress',
        complete: 'Complete',
      },
      inProgressWalks: [],
      inReviewWalks: [],
      isCompletedWalkDialogVisible: false,
      isGeneratingReport: false,
      isLoadingsettings: false,
      isNewWalkDialogVisible: false,
      recentlyCompletedWalks: [],
      sortBy: null,
      filters: {
        awaitingMyAction: false,
        showArchived: false,
        dateRange: [],
        schoolIds: [],
        walkTypes: [],
        userIds: [],
      },
      isShowingDateRangeMenu: false,
      isInitialLoadDone: false,
      currentTab: 0,
      numInProgressToShow: 26,
      numCompletedToShow: 26,
    };
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'organizationSettings',
      'user',
      'walks',
      'haveWalksLoaded',
      'usersById',
    ]),
    inProgressTableHeaders () {
      return [
        { text: 'School', align: 'left', value: 'schoolName' },
        { text: 'Time Started', align: 'left', value: 'timeCreated' },
        { text: 'Observations', align: 'left', value: 'observations' },
        { value: 'action' },
      ];
    },
    completedTableHeaders () {
      return [
        { text: 'School', align: 'left', value: 'schoolName' },
        { text: 'Time Started', align: 'left', value: 'timeCreated' },
        { text: 'Observations', align: 'left', value: 'observations' },
        { value: 'action' },
        { value: 'reports' },
      ];
    },
    schoolItems() {
      if (!this.organizationSettings || !this.organizationSettings.schools) {
        return [];
      }
      return this.organizationSettings.schools.map((school) => {
        return {
          text: school.name,
          value: school.id,
        }
      }).toSorted((a, b) => {
        return a.text.localeCompare(b.text)
      })
    },
    tableHeaders() {
      return [
        { text: 'Time Started', align: 'left', value: 'timeCreated' },
        { text: 'Status', align: 'left', value: 'status' },
        { text: 'Observations', align: 'left', value: 'observations' },
        { value: 'action' },
      ];
    },
    filterOptions () {
      return []
    },
    dateRangeText() {
      if (this.filters.dateRange.length === 2) {
        // Format the dates as needed
        const startDate = new Date(`${this.filters.dateRange[0]} 00:00:00`).toLocaleDateString();
        const endDate = new Date(`${this.filters.dateRange[1]} 00:00:00`).toLocaleDateString();
        return `${startDate} - ${endDate}`;
      } else {
        return 'All Time';
      }
    },
    filteredInProgressWalks () {
      return this.filteredWalks.filter(walk => 'inprogress' === walk.status)
    },
    filteredInReviewWalks () {
      return this.filteredWalks.filter(walk => 'review' === walk.status)
    },
    filteredCompletedWalks () {
      return this.filteredWalks.filter(walk => 'complete' === walk.status)
    },
    filteredWalks () {
      if (!this.walks) {
        return []
      }
      return this.walks.filter((walk) => {
        if (!this.filters.showArchived && walk.timeArchived) {
          return false
        }
        if (this.filters.awaitingMyAction) {
          if ('complete' === walk.status) {
            return false
          }
          if ('inprogress' === walk.status && (walk.leaderUserId !== this.user.id && !(walk.collaborators && walk.collaborators.includes(this.user.id)))) {
            return false
          }
          if ('review' === walk.status && walk.awaitingReviewBy !== this.user.id) {
            return false
          }
        }
        if (this.filters.schoolIds && this.filters.schoolIds.length) {
          if (!this.filters.schoolIds.includes(walk.schoolId)) {
            return false
          }
        }
        if (this.filters.walkTypes && this.filters.walkTypes.length) {
          if (!this.filters.walkTypes.includes(walk.walkType)) {
            return false
          }
        }
        if (this.filters.userIds && this.filters.userIds.length) {
          if (!this.filters.userIds.includes(walk.leaderUserId) && (!walk.collaborators || !walk.collaborators.some(collaborator => this.filters.userIds.includes(collaborator)))) {
            return false
          }
        }
        if (this.filters.dateRange && 2 === this.filters.dateRange.length) {
          const startDate = new Date(this.filters.dateRange[0].replace(/-/g, '/'));
          const endDate = new Date(this.filters.dateRange[1].replace(/-/g, '/'));
          const dateToCheck = new Date(walk.timeCreated);

          // Adjust the end date to include the entire day
          endDate.setHours(23, 59, 59, 999);

          if (!(dateToCheck >= startDate && dateToCheck <= endDate)) {
            return false
          }
        }
        return true
      }).sort((a, b) => a.timeCreated > b.timeCreated ? -1 : 1)
    },
    hasMoreInProgress () {
      return this.filteredInProgressWalks.length > this.numInProgressToShow
    },
    hasMoreCompleted () {
      return this.filteredCompletedWalks.length > this.numCompletedToShow
    },
    numberOfActiveMenuFilters () {
      let sum = 0
      if (this.filters.walkTypes.length) {
        sum += 1
      }
      if (this.filters.userIds.length) {
        sum += 1
      }
      if (this.filters.showArchived) {
        sum += 1
      }
      if (!this.$vuetify.breakpoint.mdAndUp) {
        if (this.filters.schoolIds.length) {
          sum += 1
        }
        if (this.filters.dateRange.length) {
          sum += 1
        }
      }
      return sum
    },
  },
  methods: {
    ...mapActions({
      showSuccess: 'app/showSuccess',
      syncWalks: 'app/syncWalks',
    }),
    async archiveWalk (walk) {
      const refreshedWalk = await Walk.getById(walk.id);
      Walk.allowedFields.forEach(field => {
        walk[field] = refreshedWalk[field];
      });

      walk.timeArchived = new Date();
      await walk.save();

      this.loadWalks();
    },
    async unarchiveWalk (walk) {
      walk.timeArchived = null;
      walk.archivedByUserId = null
      await walk.save();

      this.loadWalks();
    },
    async setWalkInProgress (walk) {
      await this.runFunction('placeWalkInProgress', {
        walkId: walk.id,
      })

      walk.status = 'inprogress'
      this.showSuccess('Successfully placed SchoolDog Walk back in progress');

      this.loadWalks();
    },
    async deleteWalk(walk) {
      await this.runFunction('archiveWalk', {
        walkId: walk.id,
      })
      this.showSuccess('Successfully deleted SchoolDog Walk');
      this.walks.splice(this.walks.indexOf(walk), 1);
    },
    async generateReport(reportType) {
      this.isGeneratingReport = true;
      this.isCompletedWalkDialogVisible = false;

      try {
        const data = await this.runFunction('generateReport', {
          reportType,
          walkId: this.completedWalkDialogWalk.id,
        });
        window.open(data, '_blank');
      } finally {
        this.isGeneratingReport = false
      }
    },
    getSchoolById (id) {
      if (this.organizationSettings) {
        return this.organizationSettings.schools.find(school => school.id === id) || {}
      }
      return {}
    },
    async loadWalks() {
      await this.syncWalks()
    },
    datesSelected() {
      if (!this.filters.dateRange || this.filters.dateRange.length != 2) {
        return;
      }
      // if both dates are selected, sort them
      const startDate = new Date(this.filters.dateRange[0].replace(/-/g, '/'));
      const endDate = new Date(this.filters.dateRange[1].replace(/-/g, '/'));
      if (startDate > endDate) {
        const newStart = this.filters.dateRange[1];
        this.filters.dateRange[1] = this.filters.dateRange[0];
        this.filters.dateRange[0] = newStart;
      }
    },
    async handleQueryParams() {
      const query = this.$route.query;
      if (query.dateRange) {
        this.filters.dateRange = query.dateRange.split(',');
      }
      if (query.schoolIds) {
        this.filters.schoolIds = query.schoolIds.split(',');
      }
      if (query.walkTypes) {
        this.filters.walkTypes = query.walkTypes.split(',');
      }
      if (query.userIds) {
        this.filters.userIds = query.userIds.split(',');
      }
      if (query.awaitingMyAction) {
        this.filters.awaitingMyAction = query.awaitingMyAction === 'true';
      }
      if (query.showArchived) {
        this.filters.showArchived = query.showArchived === 'true';
      }
      if (query.currentTab) {
        this.currentTab = parseInt(query.currentTab);
      }
    },
    fetchMoreInProgress() {
      this.numInProgressToShow += 26;
    },
    fetchMoreCompleted() {
      this.numCompletedToShow += 26;
    },
  },
  async mounted() {
    await this.handleQueryParams();
    await this.loadWalks();
    if (this.filteredCompletedWalks.length && !this.filteredInProgressWalks.length) {
      this.currentTab = 1;
    }
  },
  watch: {
    filters: {
      handler () {
        this.$router.replace({
          query: {
            ...this.filters,
            dateRange: this.filters.dateRange.join(','),
            schoolIds: this.filters.schoolIds.join(','),
            walkTypes: this.filters.walkTypes.join(','),
            userIds: this.filters.userIds.join(','),
            currentTab: this.currentTab,
          },
        });
      },
      deep: true,
    },
    currentTab () {
      this.$router.replace({
        query: {
          ...this.filters,
          dateRange: this.filters.dateRange.join(','),
          schoolIds: this.filters.schoolIds.join(','),
          walkTypes: this.filters.walkTypes.join(','),
          userIds: this.filters.userIds.join(','),
          currentTab: this.currentTab,
        },
      });
    },
    haveWalksLoaded: {
      handler () {
        if (this.haveWalksLoaded && !this.isInitialLoadDone) {
          this.isInitialLoadDone = true
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-select__selections {
  height: 40px;
  overflow: ell;
}
</style>
