<template>
  <v-card class="mb-2" v-if="rubric">
    <v-card-title class="pb-2">
      <v-btn-toggle>
      </v-btn-toggle>
      <rubric-score
        v-if="readonly && !preview"
        :rubric="rubric" />
        {{rubric.name}} {{ previewText }}
      <v-spacer/>
      <v-card-subtitle class="pa-0" v-if="!rubric.skip">
        {{totalScore}}/{{maxScore}}
      </v-card-subtitle>
    </v-card-title>
    <v-card-subtitle class="pt-0 pb-1" v-if="!readonly">
      <v-checkbox
        v-model="rubric.skip"
        color="primary"
        hide-details
        label="Skip this rubric"
        @change="rubricUpdated('checkbox')"
      />
    </v-card-subtitle>
    <template v-if="!rubric.skip">
      <v-expansion-panels  accordion multiple v-model="expandedSections">
        <v-expansion-panel v-for="(section, sectionIndex) in rubric.sections" :key="`section-${sectionIndex}`">
          <v-expansion-panel-header v-if="rubric.sections.length > 1">
            <div class="text-subtitle-1">
              {{section.title || `Section ${sectionIndex + 1}`}}
            </div>
            <v-spacer/>
            <div class="text-right mr-1">{{totalScoresBySectionIndex[sectionIndex]}}/{{maxScoresBySectionIndex[sectionIndex]}}</div>
          </v-expansion-panel-header>
          <component :is="rubric.sections.length > 1 ? 'VExpansionPanelContent' : 'div'" :class="{'pa-2': rubric.sections.length === 1}">
            <v-textarea
              v-if="(!readonly || preview) && section.showNotes"
              v-model="section.description"
              @blur="rubricUpdated('description')"
              label="Notes/Context"
              hide-details="auto"
              :readonly="readonly"
              class="mb-1"
              outlined
              dense
              rows="2"
              counter="200"
              maxlength="200" />
            <div v-else-if="section.description">
            <div class="text-body-2 mb-1">
              <div class="font-weight-thin text-caption">
                Notes/Context
              </div>
              <div class="font-weight-medium">
                {{ section.description }}
              </div>
            </div>
            </div>
            <v-expansion-panels accordion multiple v-model="expandedBySectionIndex[sectionIndex]">
              <v-expansion-panel
                v-for="(question, qIndex) in section.questions"
                :key="question.questionId"
              >
                <v-expansion-panel-header>
                  <v-col cols=8 class="pa-0">
                    <b>#{{ qIndex + 1 }}: ({{maxValue(question)}} Points) {{question.title}}</b>
                  </v-col>
                  <v-col cols=4 class="pt-0 pb-0 pl-1 pr-1 text-right">
                    <v-tooltip top v-if="autolinked(question)">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          style="font-size: 1.2em"
                          @click.stop
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span class="text-align-center">
                        Scored automatically
                        <br>
                        using linked observation entries
                      </span>
                    </v-tooltip>
                    <span v-if="autolinked(question)">
                      <b>
                        {{question.score}}
                      </b>
                    </span>
                    <span v-else>
                      {{question.score ?? '-'}}
                    </span>
                    /{{maxValue(question)}}
                  </v-col>
                  <v-spacer/>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="question.body">
                  {{question.body}}
                </v-expansion-panel-content>
                <v-expansion-panel-content>
                  <v-alert
                    v-if="question.context"
                    border="left"
                    color="#657988"
                    dark
                  >
                    <v-col class="pt-0 pb-0">
                      {{question.context.label}}<br>
                      <v-btn class="mt-1" @click="showContext(question.context)">See More</v-btn>
                    </v-col>
                  </v-alert>
                  <v-radio-group v-model="question.score" @change="questionAnswered(question)" :readonly="readonly">
                    <div v-for="answer, index in question.answers" :key="answer.answerId" class="flex">
                      <v-radio class="mb-0 rubric-answers" :value="answer.value" :ripple="!readonly">
                        <template #label>
                          <label>
                            <b>{{getLetter(index)}}) {{answer.value}} Points:</b>
                            <span v-if="answer.text.length == 1" class="ml-1">{{answer.text[0]}}</span>
                          </label>
                        </template>
                      </v-radio>
                      <ul v-if="answer.text.length > 1">
                        <li v-for="(text, index) in answer.text" :key="index" style="font-size: 14px; color: rgba(0, 0, 0, 0.6)">
                          {{text}}
                        </li>
                      </ul>
                    </div>
                  </v-radio-group>
                  <v-textarea
                    v-if="(!readonly || preview) && question.showNotes"
                    v-model="question.description"
                    @blur="rubricUpdated('description')"
                    label="Notes/Context"
                    hide-details="auto"
                    class="mb-1"
                    :readonly="readonly"
                    outlined
                    dense
                    rows="2"
                    counter="200"
                    maxlength="200" />
                  <div v-else-if="question.description">
                    <div class="text-body-2 mb-1">
                      <div class="font-weight-thin text-caption">
                        Notes/Context
                      </div>
                      <div class="font-weight-medium">
                        {{ question.description }}
                      </div>
                    </div>
                  </div>
                  <v-btn v-if="!preview" color="primary" @click="showLinkedObservationsForQuestion(question)">{{ linkedObservationText(question.linkedObservations) }}</v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </component>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <v-card-actions v-if="showClose">
      <v-spacer></v-spacer>
      <v-btn color="grey darken-1" text @click="close">{{ $t('common.close') }}</v-btn>
    </v-card-actions>
    <v-dialog v-model="contextVisibile" max-width="528px">
      <v-card v-if="contextToShow">
        <v-card-title>
          <div class="col pa-0">
            {{contextToShow.label}}
          </div>
          <v-btn class="align-self-start float-right" icon @click="hideContext()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <ul id="context-list">
            <li v-for="(text, index) in contextToShow.values" :key="index">
              {{text}}
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showLinkedObservations" @input="closeLinkingModal()" width="auto" max-width="528px">
      <v-card>
        <v-card-title>
          {{ linkedObservationText(linkedObservations) }}
          <v-spacer/>
          <v-btn class="float-right" icon @click="closeLinkingModal()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-list>
          <v-list-item
            v-for="observation in orderedObservations || []"
            :key="observation.id"
            class="linked-observations-item"
          >
            <observation-card
              v-if="!readonly || isObservationLinked(observation.id)"
              @delete="deleteObservation(observation)"
              @edit="editObservation(observation.id)"
              @link="updateObservationLink(observation.id)"
              :showLinked="true"
              :linked="isObservationLinked(observation.id)"
              :readonly="readonly"
              :observation="observation"
              :observation-number="observation.observationNumber"
              :settings="observationSettings"
              class="mb-2 width-100">
            </observation-card>
          </v-list-item>
        </v-list>
        <v-card-actions v-if="!readonly">
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeLinkingModal()"
          >
            {{$t('common.cancel')}}
          </v-btn>
          <v-btn
            color="primary"
            @click="saveUpdatedLinks()"
          >
            {{$t('common.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import ObservationCard from '@/components/walks/ObservationCard.vue';
import {  VExpansionPanelContent } from 'vuetify/lib';
import RubricScore from './RubricScore.vue';

/*
outline of a question in a rubric
default rubric is below
{
  questionId: '',
  title: '',
  body: ``,
  answers: [
    {
      id: '',
      text: [
        '', // if there's only 1 line of text it will be rendered inline with the radio button & point value
        '', // if there's multiple then they will be rendered as a list beneath the radio & point value
      ],
      value: 1
    },
  ]
}
*/
export default {
  components: {
    ObservationCard,
    VExpansionPanelContent,
    RubricScore,
  },
  props: {
    rubric: {
      type: Object,
      required: true,
    },
    allObservations: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedBySectionIndex: {},
      contextVisibile: false,
      contextToShow: null,
      linkingQuestionId: null,
      linkedObservations: [],
      showLinkedObservations: false,
      expandedSections: this.rubric.sections.map((section, index) => index),
    };
  },
  watch: {
    rubric: {
      handler() {
        this.updateAnswersBasedOnLinkedObservations();
      },
      deep: true,
    },
  },
  mounted() {
    this.updateAnswersBasedOnLinkedObservations();
    this.rubric.sections.forEach((section, sectionIndex) => {
      this.expandedBySectionIndex[sectionIndex] = this.expandedBySectionIndex[sectionIndex] ?? [];
      section.questions.forEach((question, index) => {
        if (!question.score && question.score != 0) {
          // it's weird but this is how the expansion panel model works
          this.expandedBySectionIndex[sectionIndex].push(index);
        }
      });
    });
  },
  computed: {
    ...mapState('app', [
      'observationSettings',
    ]),
    totalScore() {
      return this.rubric.sections.reduce((sum, section) => {
        return sum + section.questions.reduce((sum, question) => sum + (parseInt(question.score ?? 0)), 0);
      }, 0);
    },
    totalScoresBySectionIndex() {
      return this.rubric.sections.map((section) => {
        return section.questions.reduce((sum, question) => sum + (parseInt(question.score ?? 0)), 0);
      });
    },
    maxScore() {
      return this.rubric.sections.reduce((sum, section) => {
        return sum + section.questions.reduce((sum, question) => sum + parseInt(this.maxValue(question) ?? 0), 0);
      }, 0);
    },
    maxScoresBySectionIndex() {
      return this.rubric.sections.map((section) => {
        return section.questions.reduce((sum, question) => sum + parseInt(this.maxValue(question) ?? 0), 0);
      });
    },
    allObservationIds() {
      return this.allObservations.map((obs) => obs.id);
    },
    orderedObservations() {
      const retVal = [
        ...this.allObservations,
      ];
      retVal.sort((obsA, obsB) => {
        const aLinked = this.isObservationLinked(obsA.id);
        const bLinked = this.isObservationLinked(obsB.id);
        if ((aLinked && bLinked) || (!aLinked && !bLinked)) {
          return new Date(obsA.timestamp) - new Date(obsB.timestamp);
        }
        if (aLinked) {
          return -1;
        }
        return 1;
      });
      return retVal;
    },
    previewText() {
      return this.preview ? this.$t('rubric.preview') : '';
    },
  },
  methods: {
    getLetter(index) {
      return String.fromCharCode(index + 97)
    },
    autolinked(question) {
      return question.autoLinkedScore && question.score != undefined && question.score != null;
    },
    maxValue(question) {
      return Math.max(...question.answers.map((answer) => answer.value));
    },
    showContext(context) {
      this.contextToShow = context;
      this.contextVisibile = true;
    },
    hideContext() {
      this.contextVisibile = false;
      this.contextToShow = null;
    },
    rubricUpdated(source) {
      this.rubric.totalScore = this.totalScore;
      this.rubric.maxScore = this.maxScore;
      this.rubric.score = Number((100 * this.totalScore / this.maxScore).toFixed(0));
      this.$emit('update:rubric', this.rubric);
    },
    showLinkedObservationsForQuestion(question) {
      this.linkingQuestionId = question.questionId;
      this.linkedObservations = [
        ...(question.linkedObservations ?? []),
      ] ;
      this.showLinkedObservations = true;
    },
    linkedObservationText(linkedObservations) {
      const filtered = (linkedObservations ?? []).filter((obsId) => this.allObservationIds.includes(obsId));
      if (this.readonly) {
        return `View linked observation entries (${filtered.length} linked)`;
      }
      return `Link observation entries (${filtered.length} linked)`;
    },
    deleteObservation(observation) {
      if (!this.readonly) {
        this.$emit('deleteObservation', observation);
      }
    },
    editObservation(observationId) {
      if (!this.readonly) {
        this.$emit('editObservation', observationId);
      }
    },
    isObservationLinked(observationId) {
      return this.linkedObservations.includes(observationId)
    },
    updateObservationLink(observationId) {
      if (this.isObservationLinked(observationId)) {
        this.linkedObservations.splice(this.linkedObservations.indexOf(observationId), 1);
      } else {
        this.linkedObservations.push(observationId);
      }
    },
    closeLinkingModal() {
      this.showLinkedObservations = false;
      this.linkedObservations = [];
      this.linkingQuestionId = null;
    },
    saveUpdatedLinks() {
      // only keep the links to observations that still exist
      this.linkedObservations = this.linkedObservations.filter((obsId) => this.allObservationIds.includes(obsId));
      this.rubric.sections.forEach((section) => {
        section.questions.forEach((question) => {
          if (question.questionId == this.linkingQuestionId) {
            question.linkedObservations = this.linkedObservations;
          }
        });
      });
      this.rubricUpdated();
      this.closeLinkingModal();
    },
    close() {
      this.$emit('close');
    },
    questionAnswered(question) {
      question.manuallyAnswered = true;
      question.autoLinkedScore = false;
      this.rubricUpdated();
    },
    updateAnswersBasedOnLinkedObservations() {
      let wasUpdated = false;
      const allQuestions = this.rubric.sections.flatMap((section) => section.questions);
      allQuestions.forEach((question) => {
        // no-op if there's no linked observations
        if ((!question.linkedObservations && !question.score) || question.manuallyAnswered) {
          return;
        }
        question.linkedObservations = question.linkedObservations ?? [];
        const observations = this.allObservations.filter(
          (observation) => question.linkedObservations.indexOf(observation.id) >= 0,
        );
        let sumPraises = 0;
        let sumConcerns = 0;
        observations.forEach((observation) => {
          if (observation.observationType == 'concern') {
            sumConcerns += observation.quantity ?? 1;
          } else if (observation.observationType == 'praise') {
            sumPraises += observation.quantity ?? 1;
          }
        });
        let score = null;
        if (observations.length > 0) {
          question.answers.forEach((answer) => {
            const expectedPraises = Number(answer.expectedPraises ?? 0);
            const expectedConcerns = Number(answer.expectedConcerns ?? 0);
            // only set the score for this answer if the expected counts are non zero
            // if they're zero then the answer has not been configured for auto scoring
            if ((expectedPraises > 0 || expectedConcerns > 0)
              && sumPraises >= expectedPraises
              && sumConcerns <= expectedConcerns
              && (score == null || Number(answer.value) > Number(score))) {
              score = answer.value;
            }
            // special case - '-1' means any number of concerns
            if (expectedConcerns == -1 && score == null && sumConcerns > 0) {
              score = answer.value;
            }
          });
        }
        wasUpdated = (question.score != score);
        question.score = score;
        question.autoLinkedScore = (score != null);

      });
      if (wasUpdated) {
        this.rubricUpdated('basedOnLinked');
      }
    },
  },
}
</script>

<style lang="scss">

.v-input--is-readonly {
  .rubric-answers {
    i {
      color: grey !important;
    }
  }
}

#context-list {
  padding-left: 15px;
  li {
    margin-bottom: 5px;
  }
}

.linked-observations-item {
  .v-sheet {
    width: 100%;
  }
}

</style>
