/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAvqAzyVipKR0_kpXtiIsh0uYCpBYAzuI0",
  "appId": "1:1070557427427:web:75d55703ff8bb4298a77d6",
  "authDomain": "schooldog-i-habersham-ga.firebaseapp.com",
  "measurementId": "G-B5FEEQ407N",
  "messagingSenderId": "1070557427427",
  "project": "schooldog-i-habersham-ga",
  "projectId": "schooldog-i-habersham-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-habersham-ga.appspot.com"
}
