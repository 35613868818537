import FirestoreModel from './FirestoreModel';

class SchoolLocations extends FirestoreModel {
    static collectionName = 'schoollocations';
    static allowedFields = [
      'usageMap',
    ]

    constructor(id, data) {
      super(id, data);
    }
}

export default SchoolLocations;
