<template>
  <div class="d-flex flex-column flex-grow-1" v-if="schoolInfo">
    <v-progress-linear v-if="isSubmittingSchoolLinksForm" indeterminate color="primary"/>
    <v-form ref="schoolLinksForm" v-model="isschoolLinksFormValid">
      <v-card v-if="schoolInfo">
        <v-card-title class="flex-grow-1 flex-row d-flex">
          {{$t('school.links')}}
          <v-spacer/>
          <v-btn class="float-right" icon color="primary" @click="handleIconClick" v-if="canEdit">
            <v-icon v-if="editing || links.length == 0">mdi-plus</v-icon>
            <v-icon v-else>mdi-pencil-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list v-if="editing && canEdit">
            <div v-if="links.length > 0">
              <v-list-item
                dense
                :class="{ mobile: !$vuetify.breakpoint.mdAndUp && editing }"
                v-for="(link, index) in links"
                :key="index"
              >
                <v-col class="pa-0" :cols="$vuetify.breakpoint.mdAndUp ? 4 : 11">
                  <v-text-field placeholder="New Link" label="Name" v-model="link.name" :prepend-icon="$vuetify.breakpoint.mdAndUp ? 'mdi-link-variant' : 'mdi-rename-outline'" :rules="[rules.required]"/>
                  <v-text-field v-if="!$vuetify.breakpoint.mdAndUp" prepend-icon="mdi-link-variant" label="Url" v-model="link.url" :rules="[rules.required]"/>
                </v-col>
                <v-text-field v-if="$vuetify.breakpoint.mdAndUp" class="pl-1" label="Url" v-model="link.url" :rules="[rules.required]"/>
                <v-col v-if="!$vuetify.breakpoint.mdAndUp" cols="1" class="pl-5 pr-0">
                  <v-btn class="float-right" icon color="primary" @click="deleteLink(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
                <v-btn v-else class="float-right" icon color="primary" @click="deleteLink(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item>
            </div>
            <div v-else>{{$t('school.addNewLinks')}}</div>
          </v-list>
          <v-list v-else-if="links.length > 0">
            <v-list-item
              dense
              :class="{'mobile': !$vuetify.breakpoint.mdAndUp && editing}"
              v-for="(link, index) in links"
              :key="index"
            >
              <v-list-item-title>
                <a :href="link.url" target="_blank">{{link.name}}</a>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <div v-else>{{$t('school.addNewLinks')}}</div>
          <v-alert v-model="errored" dismissable dense outlined text type="error">
            {{$t('school.uploadError')}}
          </v-alert>
        </v-card-text>
        <v-card-actions v-if="editing && canEdit">
          <v-spacer></v-spacer>
          <v-btn
            @click="cancel()"
            color="grey darken-1"
            text
          >Cancel
          </v-btn>
          <v-btn
            @click="submitEditInfo()"
            :disabled="isSubmittingSchoolLinksForm"
            color="primary"
          >Save Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    schoolId: null,
    schoolInfo: null,
  },
  data() {
    return {
      links: this.schoolInfo.links ? this.schoolInfo.links.map((link) => ({...link})) : [],
      rules: {
        required: value => Boolean(value) || 'This field is required',
        email: value => Boolean(!value) || (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(value) || 'Please enter a valid email',
        phone: value => Boolean(!value) || (/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/).test(value) || 'Please enter a valid phone number',
      },
      isSubmittingSchoolLinksForm: false,
      errored: false,
      isschoolLinksFormValid: false,
      editing: false,
    };
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'user',
    ]),
    canEdit() {
      return !this.isOffline && this.user.canAtSchool('manage schools', this.schoolId);
    },
  },
  watch: {
    schoolInfo() {
      this.links = this.schoolInfo.links ? [
        ...this.schoolInfo.links, 
      ] : [];
    },
  },
  methods: {
    async submitEditInfo () {
      if (this.$refs.schoolLinksForm.validate() && !this.isSubmittingSchoolLinksForm) {
        this.isSubmittingSchoolLinksForm = true
        this.errored = false;
        try {
          await this.runFunction('editSchoolLinks', {
            schoolId: this.schoolId,
            schoolInfo: {
              links: this.links,
            },
          })
          this.$emit('schoolLinksUpdated', this.links);
          this.editing = false;
        } catch(e) {
          this.errored = true;
        } finally {
          this.isSubmittingSchoolLinksForm = false
        }
      }
    },
    cancel() {
      this.errored = false;
      this.editing = false;
      this.editInfo = null;
      this.links = this.schoolInfo.links ? this.schoolInfo.links.map((link) => ({...link})) : [],
      this.$emit('cancel');
    },
    addLink() {
      this.links.push({name: 'New Link', url: null});
    },
    deleteLink(index) {
      this.links.splice(index, 1);
    },
    startEditing() {
      this.editing = true;
    },
    handleIconClick() {
      if (!this.canEdit) {
        return; // shouldn't be able to click if you can't edit
      }
      if (this.editing) {
        this.addLink();
      } else if (!this.editing && this.links.length == 0) {
        this.startEditing();
        this.addLink();
      } else {
        this.startEditing();
      }
    },
  },
};
</script>

<style>
.v-list-item.mobile {
  border-bottom: 1px rgba(0, 0, 0, 0.87) dashed;
}
</style>
