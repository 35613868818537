<template>
<v-dialog :value="value" @input="$emit('input', $event)" max-width="628px">
    <mfa-card
      v-if="isRequestingMfa"
      @cancel="cancelMfa($event)"
      @submitMfa="submitMfa($event)"
      :is-loading="isMfaLoading"
      :mfa-error="mfaError"
      :submission-error="mfaSubmissionError" />
    <v-card v-else class="text-center pa-1">
      <v-card-title class="justify-center display-1 mb-2">
        Sign In
      </v-card-title>
      <v-card-subtitle>
        The action you are taking requires you to reauthenticate. Please sign in again to continue.
      </v-card-subtitle>

      <!-- sign in form -->
      <v-card-text>
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <v-text-field
            v-model="email"
            :rules="[rules.required]"
            :validate-on-blur="false"
            :error="error"
            :label="$t('login.email')"
            name="email"
            outlined
            @keyup.enter="submit"
            @change="resetErrors"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="showPassword ? 'text' : 'password'"
            :error="error"
            :error-messages="errorMessages"
            :label="$t('login.password')"
            name="password"
            outlined
            @change="resetErrors"
            @keyup.enter="submit"
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-btn
            :loading="isLoading"
            :disabled="isSignInDisabled"
            block
            x-large
            color="primary"
            @click="submit"
          >{{ $t('login.button') }}</v-btn>

          <div class="caption font-weight-bold text-uppercase my-3">Or Sign In With</div>

          <!-- external providers list -->
          <v-btn
            v-for="provider in providers"
            :key="provider.id"
            :loading="provider.isLoading"
            :disabled="isSignInDisabled"
            class="mb-2 primary lighten-4 primary--text text--darken-3"
            block
            x-large
            @click="signInProvider(provider.id)"
          >
            <v-icon small left>mdi-{{ provider.id }}</v-icon>
            {{ provider.label }}
          </v-btn>

          <div v-if="errorProviderMessages" class="error--text">{{ errorProviderMessages }}</div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
        color="grey darken-1"
          text
          @click="$emit('input', false)"
        >
          Cancel
        </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { GoogleAuthProvider, PhoneAuthProvider, reauthenticateWithCredential, reauthenticateWithPopup, PhoneMultiFactorGenerator, EmailAuthProvider } from 'firebase/auth';

import MfaCard from '../auth/MfaCard.vue';

export default {
  name: 'ReauthenticateDialog',
  components: { MfaCard },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // sign in buttons
      isLoading: false,
      isSignInDisabled: false,

      // form
      isFormValid: true,
      email: '',
      password: '',

      // mfa form
      isMfaFormValid: true,
      smsCode: '',
      isRequestingMfa: false,
      verificationId: '',

      // form error
      error: false,
      errorMessages: '',

      errorProvider: false,
      errorProviderMessages: '',

      // show password field
      showPassword: false,

      providers: [
        {
          id: 'google',
          label: 'Google',
          isLoading: false,
        },
      ],

      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required',
      },

      recaptchaVerifier: null,
      isMfaSubmitDisabled: false,

      mfaError: null,
      mfaSubmissionError: null,
      isMfaLoading: false,
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.isSignInDisabled = true;
        this.signIn();
      }
    },
    async signIn() {
      try {
        this.isLoading = true
        this.isSignInDisabled = true
        const credential = EmailAuthProvider.credential(this.email, this.password);
        await reauthenticateWithCredential(this.auth.currentUser, credential);
        this.$emit('reauthenticated')
      } catch (error) {
        if (error.code === 'auth/multi-factor-auth-required') {
          this.mfaError = error;
          this.isRequestingMfa = true
        } else if (error.code === 'auth/user-mismatch') {
          this.errorProviderMessages = 'The account you are trying to sign in with does not match the account you are currently signed in with. Please sign in with the correct account.';
        } else if (error.code === 'auth/invalid-login-credentials') {
          this.errorProviderMessages = 'Invalid email or password. Please try again.';
        } else if (error.code !== 'auth/popup-closed-by-user') {
          this.errorProviderMessages = error.message;
        }
      } finally {
        this.isLoading = false;
        this.isSignInDisabled = false;
      }
    },
    async submitMfa({ verificationId, smsCode, resolver }) {
      try {
        this.isMfaLoading = true;
        const phoneAuthCredential = PhoneAuthProvider.credential(verificationId, smsCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
        await resolver.resolveSignIn(multiFactorAssertion);
        this.$emit('reauthenticated')
      } catch (error) {
        this.mfaSubmissionError = error;
      } finally {
        this.isMfaLoading = false;
      }
    },
    cancelMfa(message) {
      this.isRequestingMfa = false;
      this.errorProviderMessages = message;
    },
    async signInProvider(provider) {
      if (provider === 'google') {
        const googleProvider = new GoogleAuthProvider();
        googleProvider.setCustomParameters({
          prompt: 'select_account',
        });
        try {
          await reauthenticateWithPopup(this.auth.currentUser, googleProvider);
          this.$emit('reauthenticated')
        } catch (error) {
          if (error.code === 'auth/multi-factor-auth-required') {
            this.mfaError = error;
            this.isRequestingMfa = true
          } else if (error.code === 'auth/user-mismatch') {
            this.errorProviderMessages = 'The account you are trying to sign in with does not match the account you are currently signed in with. Please sign in with the correct account.';
          } else if (error.code !== 'auth/popup-closed-by-user') {
            this.errorProviderMessages = error.message;
          }
        }
      }
    },
    resetErrors() {
      this.error = false;
      this.errorMessages = '';

      this.errorProvider = false;
      this.errorProviderMessages = '';
    },
  },
};
</script>

<style scoped>
</style>
