<template>
  <div class="d-flex flex-column flex-grow-1" v-if="school">
    <v-progress-linear v-if="isSubmittingSchoolForm" indeterminate color="primary"/>
    <v-form ref="editSchoolForm" v-model="isSchoolFormValid">
      <v-card>
        <v-card-title v-if="!editing" class="flex-grow-1 flex-row d-flex">
          School Info
          <v-spacer/>
          <v-btn class="float-right" icon color="primary" @click="startEditing" v-if="canEdit">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title v-else>
          {{$t('school.school')}}
        </v-card-title>
        <v-card-text v-if="editing && canEdit">
          <v-text-field
              :label="$t('school.name')"
              v-model="editSchool.name"
              :rules="[rules.required]"
              class="mb-1"
              dense
              hide-details="auto"
              outlined
              required
          />
          <!-- Could probably split this out into the standard addr1/2/3 city state zip if we want to-->
          <v-text-field
              :label="$t('school.address')"
              v-model="editSchool.address"
              :rules="[]"
              class="mb-1"
              dense
              hide-details="auto"
              outlined
              required
          />
          <!-- <v-text-field
              :label="$t('school.email')"
              v-model="editSchool.contactEmail"
              :rules="[rules.email]"
              class="mb-1"
              dense
              hide-details="auto"
              outlined
              required
          />
          <v-text-field
              :label="$t('school.phone')"
              v-model="editSchool.contactPhone"
              :rules="[rules.phone]"
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              class="mb-1"
              dense
              hide-details="auto"
              outlined
              required
          /> -->
            <v-text-field
              :label="$t('school.principalName')"
              v-model="editSchool.principalName"
              :rules="[]"
              class="mb-1"
              dense
              hide-details="auto"
              outlined
              required
          />
            <v-text-field
              :label="$t('school.principalOfficePhone')"
              v-model="editSchool.principalOfficePhone"
              :rules="[rules.phone]"
              class="mb-1"
              dense
              hide-details="auto"
              outlined
              required
          />
          <v-text-field
              :label="$t('school.schoolOfficeNumber')"
              v-model="editSchool.schoolOfficeNumber"
              :rules="[rules.phone]"
              class="mb-1"
              dense
              hide-details="auto"
              outlined
              required
          />
            <v-text-field
              :label="$t('school.principalEmail')"
              v-model="editSchool.principalEmail"
              :rules="[rules.email]"
              class="mb-1"
              dense
              hide-details="auto"
              outlined
              required
          />
          <!-- <v-text-field
              :label="$t('school.fax')"
              v-model="editSchool.fax"
              :rules="[rules.fax]"
              type="fax"
              pattern="\+[0-9]{3}\.[0-9]{3}\.[0-9]{4}"
              class="mb-1"
              dense
              hide-details="auto"
              outlined
              required
          /> -->
          <v-text-field
              :label="$t('school.website')"
              v-model="editSchool.website"
              :rules="[]"
              class="mb-1"
              dense
              hide-details="auto"
              outlined
              required
          />

          <v-alert v-model="errored" dismissable dense outlined text type="error">
            {{$t('school.uploadError')}}
          </v-alert>
        </v-card-text>
        <v-card-text v-else>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('school.schoolName')}}
                </v-list-item-title>
                <div>
                  <div v-if="editSchool.name">{{editSchool.name}}</div>
                  <v-list-item-subtitle v-else>
                    {{$t('school.noSchoolName')}}
                  </v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('school.address')}}
                </v-list-item-title>
                <div>
                  <a v-if="editSchool.address" :href="`http://maps.google.com/?q=${editSchool.address}`">
                    {{editSchool.address}}
                  </a>
                  <v-list-item-subtitle v-else>
                    {{$t('school.noAddress')}}
                  </v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item >
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('school.email')}}
                </v-list-item-title>
                <div>
                  <a v-if="editSchool.contactEmail" :href="`mailto:${editSchool.contactEmail}`">{{editSchool.contactEmail}}</a>
                  <v-list-item-subtitle v-else>
                    {{$t('school.noEmail')}}
                  </v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item> -->
            <!-- <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('school.phone')}}
                </v-list-item-title>
                <div>
                  <a v-if="editSchool.contactPhone" :href="`tel:${cleanPhone}`">{{editSchool.contactPhone}}</a>
                  <v-list-item-subtitle v-else>
                    {{$t('school.noPhone')}}
                  </v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('school.principalName')}}
                </v-list-item-title>
                <div>
                  <div v-if="editSchool.principalName">{{editSchool.principalName}}</div>
                  <v-list-item-subtitle v-else>
                    {{$t('school.noPrincipalName')}}
                  </v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('school.principalOfficePhone')}}
                </v-list-item-title>
                <div>
                  <a v-if="editSchool.principalOfficePhone" :href="`tel:${cleanPrincipalOfficePhone}`">{{editSchool.principalOfficePhone}}</a>
                  <v-list-item-subtitle v-else>
                    {{$t('school.noPrincipalOfficePhone')}}
                  </v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item >
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('school.principalEmail')}}
                </v-list-item-title>
                <div>
                  <a v-if="editSchool.principalEmail" :href="`mailto:${editSchool.principalEmail}`">{{editSchool.principalEmail}}</a>
                  <v-list-item-subtitle v-else>
                    {{$t('school.noPrincipalEmail')}}
                  </v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('school.schoolOfficeNumber')}}
                </v-list-item-title>
                <div>
                  <a v-if="editSchool.schoolOfficeNumber" :href="`tel:${cleanOfficeNumber}`">{{editSchool.schoolOfficeNumber}}</a>
                  <v-list-item-subtitle v-else>
                    {{$t('school.noSchoolOfficeNumber')}}
                  </v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('school.fax')}}
                </v-list-item-title>
                <div>
                  <a v-if="editSchool.fax" :href="`fax:${cleanFax}`">{{editSchool.fax}}</a>
                  <v-list-item-subtitle v-else>
                    {{$t('school.noFax')}}
                  </v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('school.website')}}
                </v-list-item-title>
                <div>
                  <a v-if="editSchool.website" :href="editSchool.website" target="_blank">{{editSchool.website}}</a>
                  <v-list-item-subtitle v-else>
                  {{$t('school.noWebsite')}}
                </v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions v-if="editing && canEdit">
          <v-spacer></v-spacer>
          <v-btn
            @click="cancel()"
            color="grey darken-1"
            text
          >Cancel
          </v-btn>
          <v-btn
            @click="submitEditSchool()"
            :disabled="isSubmittingSchoolForm"
            color="primary"
          >Save Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
// Firebase imports
import uuid from 'uuid'
import { mapState } from 'vuex';

export default {
  props: {
    school: Object,
    isModal: Boolean,
  },
  data() {
    return {
      editSchool: { ...this.school },
      rules: {
        required: value => Boolean(value) || 'This field is required',
        email: value => Boolean(!value) || (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(value) || 'Please enter a valid email',
        phone: value => Boolean(!value) || (/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/).test(value) || 'Please enter a valid phone number',
        fax: value => Boolean(!value) || (/^\+?(\d{3})[\. ]?(\d{3})[\. ]?(\d{4})$/).test(value) || 'Please enter a valid phone number',
      },
      isSubmittingSchoolForm: false,
      errored: false,
      isSchoolFormValid: false,
      editing: this.isModal,
    };
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'user',
    ]),
    cleanPhone() {
      return this.editSchool.contactPhone ? this.editSchool.contactPhone.replace(/\D/g, '') : '';
    },
    cleanFax() {
      return this.editSchool.fax ? this.editSchool.fax.replace(/\D/g, '') : '';
    },
    cleanOfficeNumber() {
      return this.editSchool.schoolOfficeNumber ? this.editSchool.schoolOfficeNumber.replace(/\D/g, '') : '';
    },
    cleanPrincipalOfficePhone() {
      return this.editSchool.principalOfficePhone ? this.editSchool.principalOfficePhone.replace(/\D/g, '') : '';
    },
    canEdit() {
      return !this.isOffline && this.user.canAtSchool('manage schools', this.school.id);
    },
  },
  watch: {
    school() {
      this.editSchool = { ...this.school };
    },
  },
  methods: {
    async submitEditSchool () {
      if (this.$refs.editSchoolForm.validate() && !this.isSubmittingSchoolForm) {
        this.isSubmittingSchoolForm = true
        this.errored = false;
        if (this.editSchool.contactPhone != this.school.contactPhone) {
          this.editSchool.contactPhone = this.formatPhone(this.editSchool.contactPhone);
        }
        if (this.editSchool.schoolOfficeNumber != this.school.schoolOfficeNumber) {
          this.editSchool.schoolOfficeNumber = this.formatPhone(this.editSchool.schoolOfficeNumber);
        }
        if (this.editSchool.principalOfficePhone != this.school.principalOfficePhone) {
          this.editSchool.principalOfficePhone = this.formatPhone(this.editSchool.principalOfficePhone);
        }
        if (this.editSchool.fax != this.school.fax) {
          this.editSchool.fax = this.formatFax(this.editSchool.fax);
        }
        try {
          await this.runFunction('schoolFormSubmit', {
            schoolId: this.school.id ?? uuid(), // use the passed in id or generate new if null (add school use case)
            // reformat the phone number to keep it standardized
            schoolData: this.editSchool,
          })
          this.$emit('schoolUpdated', this.editSchool);
          if (!this.isModal) {
            this.editing = false;
          }
        } catch(e) {
          this.errored = true;
        } finally {
          this.isSubmittingSchoolForm = false
        }
      }
    },
    cancel() {
      this.errored = false;
      this.editSchool = {...this.school};
      if (!this.isModal) {
        this.editing = false;
      }
      this.$emit('cancel');
    },
    formatPhone(phoneNumberString) {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null;
    },
    formatFax(faxString) {
      var cleaned = ('' + faxString).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '+' + match[1] + '.' + match[2] + '.' + match[3];
      }
      return null;
    },
    startEditing() {
      this.editing = true;
    },
  },
};
</script>
