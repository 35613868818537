import { functions } from '@/firebase-setup'
import { httpsCallable } from 'firebase/functions'

class FunctionsService {
  static async run(functionName, data = {}) {
    try {
      const callFunction = httpsCallable(functions, functionName);
      const result = await callFunction(data);
      return result.data;
    } catch (error) {
      console.error(`Error calling ${functionName}:`, error);
      throw error; // Or handle the error as needed
    }
  }
}

export default FunctionsService;