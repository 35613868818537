<template>
  <!-- Menu is enabled, wrap avatar with v-menu -->
  <v-menu v-if="!preventClick && user" offset-y :open-on-hover="$vuetify.breakpoint.mdAndUp">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-avatar
          v-bind="$attrs"
          :color="user.id === currentUser.id ? 'blue-grey' : 'purple darken-2'"
        >
          <div class="white--text font-weight-bold">
            {{ user.firstName.charAt(0) }}{{ user.lastName.charAt(0) }}
          </div>
        </v-avatar>
      </div>
    </template>

    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{user.firstName}} {{user.lastName}}</v-list-item-title>
          <v-list-item-subtitle>{{user.title || user.friendlyRole}}</v-list-item-subtitle>
          <!-- Add more user details here -->
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item v-if="!user.isDistrictUser() && user.roleSchoolIds.length">
        <v-list-item-content>
          <v-list-item-title>
            School{{ user.roleSchoolIds.length > 1 ? 's' : '' }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ schoolDescription}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Email
          </v-list-item-title>
          <v-list-item-subtitle>
            {{user.email}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!-- You can add more list items for additional info -->
    </v-list>
  </v-menu>
  <div v-else-if="user">
    <!-- Menu is disabled, just show the avatar -->
    <v-avatar
      v-bind="$attrs"
      :color="user.id === currentUser.id ? 'blue-grey' : 'darken-2'"
    >
      <div class="white--text">
        {{ user.firstName.charAt(0) }}{{ user.lastName.charAt(0) }}
      </div>
    </v-avatar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  props: {
    preventClick: Boolean,
    userId: String,
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    ...mapState({
      currentUser: (state) => state.app.user,
      usersById: state => state.app.usersById,
    }),
    user () {
      return this.usersById[this.userId]
    },
    schoolDescription () {
      if (!this.user.isDistrictUser()) {
        const schoolIds = this.user.roleSchoolIds;
        let str = '';
        for (let i = 0; i < schoolIds.length; i++) {
          const school = this.getSchoolById(schoolIds[i]);
          if (school) {
            str += school.name;
            if (i < schoolIds.length - 1) {
              str += ', ';
            }
          }
        }
        return str;
      }
      return null;
    },
  },
  methods: {
    showUserDialog() {
      console.warn('showUserDialog');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
