<template>
  <div>
    <mfa-card
      v-if="isRequestingMfa"
      @cancel="cancelMfa($event)"
      @submitMfa="submitMfa($event)"
      :is-loading="isMfaLoading"
      :mfa-error="mfaError"
      :submission-error="mfaSubmissionError" />
    <v-card v-else class="text-center pa-1">
      <v-card-title class="justify-center display-1 mb-2">
        Sign In
      </v-card-title>
      <v-card-subtitle>
        Sign into your account.
      </v-card-subtitle>

      <!-- sign in form -->
      <v-card-text>
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <v-text-field
            v-model="email"
            :rules="[rules.required]"
            :validate-on-blur="false"
            :error="error"
            :label="$t('login.email')"
            name="email"
            outlined
            @keyup.enter="submit"
            @change="resetErrors"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="showPassword ? 'text' : 'password'"
            :error="error"
            :error-messages="errorMessages"
            :label="$t('login.password')"
            name="password"
            outlined
            @change="resetErrors"
            @keyup.enter="submit"
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-btn
            :loading="isLoading"
            :disabled="isSignInDisabled"
            block
            x-large
            color="primary"
            @click="submit"
          >{{ $t('login.button') }}</v-btn>

          <div class="caption font-weight-bold text-uppercase my-3">Or Sign In With</div>

          <!-- external providers list -->
          <v-btn
            v-for="provider in providers"
            :key="provider.id"
            :loading="provider.isLoading"
            :disabled="isSignInDisabled"
            class="mb-2 primary lighten-4 primary--text text--darken-3"
            block
            x-large
            @click="signInProvider(provider.id)"
          >
            <v-icon small left>mdi-{{ provider.id }}</v-icon>
            {{ provider.label }}
          </v-btn>

          <div v-if="errorProviderMessages" class="error--text">{{ errorProviderMessages }}</div>

          <div class="mt-3 d-flex justify-center">
            <div>
              <router-link to="/auth/forgot-password">
                Reset password
              </router-link>
            </div>
            <span class="mx-1">•</span>
            <div>
              <router-link to="/auth/sign-in-link">
                Sign in without password
              </router-link>
            </div>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog v-model="isAuthTimeoutDialogVisible" max-width="528px">
      <v-card>
        <v-card-title>
          Your Session Timed Out
          <v-spacer></v-spacer>
          <v-btn icon @click="isAuthTimeoutDialogVisible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          SchoolDog was unable to verify your account. This is usually due to a poor internet connection. Please try again when you have a stable internet connection, or alternatively you can use SchoolDog in offline/airplane mode and sync your data later.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="isAuthTimeoutDialogVisible = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isAlreadySignedInDialogVisible" max-width="528px">
      <v-card v-if="user">
        <v-card-title>
          You are already signed in
          <v-spacer></v-spacer>
          <v-btn icon @click="isAlreadySignedInDialogVisible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          You are already signed in as {{ user.email }}. If this is you, please continue. If not, please sign in using your correct credentials.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" @click="isAlreadySignedInDialogVisible = false" text>Sign in as different user</v-btn>
          <v-btn color="primary" @click="$router.replace('/dashboard')">Continue as this user</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from 'firebase/auth';
import * as Sentry from '@sentry/vue';
import {mapActions, mapState } from 'vuex'
import User from '../../models/User';
import MfaCard from '../../components/auth/MfaCard.vue';

export default {
  components: { MfaCard },
  data() {
    return {
      // sign in buttons
      isLoading: false,
      isSignInDisabled: false,

      // form
      isFormValid: true,
      email: '',
      password: '',

      // mfa form
      isMfaFormValid: true,
      smsCode: '',
      isRequestingMfa: false,
      verificationId: '',

      // form error
      error: false,
      errorMessages: '',

      errorProvider: false,
      errorProviderMessages: '',

      // show password field
      showPassword: false,

      providers: [
        {
          id: 'google',
          label: 'Google',
          isLoading: false,
        },
      ],

      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required',
      },

      recaptchaVerifier: null,
      isMfaSubmitDisabled: false,

      mfaError: null,
      mfaSubmissionError: null,
      isMfaLoading: false,
      isAuthTimeoutDialogVisible: false,
      isAlreadySignedInDialogVisible: false,
    };
  },
  computed: {
    ...mapState('app', [
      'user',
      'isManuallyOffline',
    ]),
  },
  methods: {
    ...mapActions('app', [
      'setManualOfflineMode',
    ]),
    submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.isSignInDisabled = true;
        this.signIn(this.email, this.password);
      }
    },
    async signIn(email, password) {
      try {
        this.isLoading = true
        this.isSignInDisabled = true
        const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
        const firebaseUser = userCredential.user;
        await this.finishSignInWithFirebaseUser(firebaseUser);
      } catch (error) {
        if (error.code === 'auth/multi-factor-auth-required') {
          this.mfaError = error;
          this.isRequestingMfa = true
        } else {
          Sentry.captureException(error);
          if (error.code === 'auth/invalid-login-credentials') {
            this.errorProviderMessages = 'Your email or password is incorrect. Please try again.';
          } else if (!error.code) {
            this.errorProviderMessages = error.message;
          } else {
            this.errorProviderMessages = 'An error occurred while attempting to sign in. Please ensure you are using the correct credentials and try again.';
          }
        }
      } finally {
        this.isLoading = false;
        this.isSignInDisabled = false;
      }
    },
    async submitMfa({ verificationId, smsCode, resolver }) {
      try {
        this.isMfaLoading = true;
        const phoneAuthCredential = PhoneAuthProvider.credential(verificationId, smsCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
        const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
        await this.finishSignInWithFirebaseUser(userCredential.user);
      } catch (error) {
        this.mfaSubmissionError = error;
      } finally {
        this.isMfaLoading = false;
      }
    },
    cancelMfa(message) {
      this.isRequestingMfa = false;
      this.errorProviderMessages = message;
    },
    async finishSignInWithFirebaseUser(firebaseUser) {
      try {
        localStorage.setItem('signInTimestamp', Date.now());
        await User.getById(firebaseUser.uid);
        this.$router.replace('/dashboard');
      } catch (e) {
        console.error(e);
        throw new Error('There was no SchoolDog account found with the provided credentials. Please ensure you are using your correct work email and try again, or you can contact your SchoolDog Site Admin for help.');
      }
    },
    async signInProvider(provider) {
      if (provider === 'google') {
        const googleProvider = new GoogleAuthProvider();
        googleProvider.setCustomParameters({
          prompt: 'select_account',
        });
        try {
          const result = await signInWithPopup(this.auth, googleProvider);
          const firebaseUser = result.user;
          await this.finishSignInWithFirebaseUser(firebaseUser);
        } catch (error) {
          if (error.code === 'auth/multi-factor-auth-required') {
            this.mfaError = error;
            this.isRequestingMfa = true
          } else {
            Sentry.captureException(error);
            if (error.code === 'auth/admin-restricted-operation') {
              this.errorProviderMessages = 'There was no SchoolDog account found with the provided credentials. Please ensure you are using your correct work email and try again, or you can contact your SchoolDog Site Admin for help.';
            } else if (!error.code) {
              this.errorProviderMessages = error.message;
            } else {
              this.errorProviderMessages = 'An error occurred while attempting to sign in. Please ensure you are using the correct credentials and try again.';
            }
          }
        }
      }
    },
    resetErrors() {
      this.error = false;
      this.errorMessages = '';

      this.errorProvider = false;
      this.errorProviderMessages = '';
    },
  },
  mounted () {
    const issue = this.$route.query.i;
    if (issue) {
      if ('authTimeout' === issue) {
        this.isAuthTimeoutDialogVisible = true
      }
      this.$router.replace({ query: {} });
    } else if (this.user) {
      // delay so we don't show dialog as a blip while in redirect chain
      setTimeout(() => {
        this.isAlreadySignedInDialogVisible = true
      }, 1000)
    }
  },
};
</script>
