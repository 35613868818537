import FirestoreModel from './FirestoreModel';

class Cycle extends FirestoreModel {
    static collectionName = 'cycle';
    static allowedFields = [
      'id',
      'startDate',
      'endDate',
      'numTotalDistrictInitiatedWalks',
      'numTotalSchoolInitiatedWalks',
      'numTotalWalks',
      'numTotalObservations',
      'numTotalConcerns',
      'numTotalPraises',
      'numTotalOther',
      'numSchoolInitiatedTotalObservations',
      'numSchoolInitiatedTotalConcerns',
      'numSchoolInitiatedTotalPraises',
      'numSchoolInitiatedTotalOther',
      'numDistrictInitiatedTotalObservations',
      'numDistrictInitiatedTotalConcerns',
      'numDistrictInitiatedTotalPraises',
      'numDistrictInitiatedTotalOther',
      'requiredNumSchoolLedObservations',
      'schools',
    ];
    constructor(id, data) {
      super(id, data);
    }
}
export default Cycle;
