import FirestoreModel from './FirestoreModel';

class SchoolInfo extends FirestoreModel {
    static collectionName = 'schoolInfo';
    static allowedFields = [
      'links',
    ]

    constructor(id, data) {
      super(id, data);
    }
}

export default SchoolInfo;