<template>
<div>
  <v-card class="text-center pa-1">
    <v-card-title class="justify-center display-1 mb-2">
      Verify your identity
    </v-card-title>
    <v-card-subtitle v-if="hint">
      We sent a verification code via SMS to {{ hint.phoneNumber }}. Please enter the code below.
    </v-card-subtitle>
    <v-card-text>
      <v-form ref="mfaForm" v-model="isMfaFormValid" lazy-validation @submit.prevent="submitMfa()">
        <v-text-field
          v-model="smsCode"
          :rules="[rules.required]"
          :validate-on-blur="false"
          label="Verification Code"
          name="smsCode"
          type="tel"
          maxlength="6"
          keyboardType="number-pad"
          outlined
          @change="resetErrors"
        ></v-text-field>

        <v-btn
          :loading="isLoading"
          :disabled="isLoading"
          block
          x-large
          color="primary"
          @click="submitMfa()"
        >Verify</v-btn>

        <div v-if="errorMessage" class="error--text mt-2">{{ errorMessage }}</div>
      </v-form>
    </v-card-text>
    <div id="recaptcha-container"></div>
  </v-card>
    <div class="text-center mt-6">
      <a @click.prevent="$emit('cancel')" class="text-decoration-underline">
        Back to Sign In
      </a>
    </div>
</div>
</template>

<script>
import { getMultiFactorResolver, PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth'
export default {
  props: {
    mfaError: Error,
    submissionError: Error,
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      errorMessage: '',
      isMfaFormValid: true,
      rules: {
        required: (value) => Boolean(value) || 'Required.',
      },
      smsCode: '',
      verificationId: '',
      resolver: null,
      recaptchaVerifier: null,
      hint: null,
    }
  },
  methods: {
    submitMfa () {
      this.$emit('submitMfa', {
        smsCode: this.smsCode,
        verificationId: this.verificationId,
        resolver: this.resolver,
      })
    },
    resetErrors () {
      this.errorMessage = ''
    },
    async setupRecaptchaVerifier() {
      const auth = this.auth
      if (!this.recaptchaVerifier) {
        this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
          size: 'invisible',
        }, auth);

        // Make sure to render the reCAPTCHA verifier
        await this.recaptchaVerifier.render();
      }
    },
    async resendCode () {
      try {
        await this.setupRecaptchaVerifier();
        const phoneAuthProvider = new PhoneAuthProvider(this.auth);
        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
          this.hint,
          this.recaptchaVerifier,
        );
      } catch (e) {
        this.errorMessage = 'Error resending code. Please try again later.';
      }
    },
  },
  async mounted () {
    const auth = this.auth
    try {
      await this.setupRecaptchaVerifier();

      const error = this.mfaError;
      const resolver = getMultiFactorResolver(auth, error);
      const phoneAuthProvider = new PhoneAuthProvider(auth);

      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };

      this.hint = phoneInfoOptions.multiFactorHint;

      this.verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier);
      this.resolver = resolver;
    } catch (e) {
      this.$emit('cancel', 'There was an error while setting up Multi-Factor Authentication. This often happens when you request too many verification codes in a short amount of time, eliciting concerns of abuse or fraud. Please wait at least an hour and try again later. If the problem persists, you can contact support for assistance.')
    }
  },
  watch: {
    submissionError () {
      if (!this.submissionError) {
        return;
      }
      if (this.submissionError.code === 'auth/invalid-verification-code') {
        this.errorMessage = 'The code you entered is incorrect. Please try again.';
      } else {
        this.errorMessage = 'Error verifying code. Please try again later.';
      }
    },
  },
}
</script>

<style>

</style>