<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    max-width="540px">
    <v-card>
      <v-card-title>
        Manage Multi-Factor Authentication
      </v-card-title>
      <v-card-text>
        <div v-if="!isEmailVerified">
          <div class="mb-2">
            You must verify your email address before enabling Multi-Factor Authentication.
          </div>
          <v-btn v-if="!hasSentEmailVerification" color="primary" text @click="runFunction('sendEmailVerification', { email: currentEmail }); hasSentEmailVerification = true">
            Send Verification Email
          </v-btn>
          <div v-else>
            <div class="mb-2">
              A verification email has been sent to {{ currentEmail }}. Please check your inbox and spam folder. It may take a few moments to arrive.
            </div>
            <a @click.prevent="reloadAuthState()" class="text-decoration-underline">Verified your email? Click here</a>
            <div v-if="isStillNotEmailVerified" class="error--text mt-2">
              Our system still shows that your email is still not verified. Please check your inbox and spam folder for the verification email. It may take a few moments to arrive.
            </div>
          </div>
        </div>
        <div v-else-if="mfaFactors.length">
            <div>
                You currently have Multi-Factor Authentication enabled using the following phone number: {{ mfaFactors[0].phoneNumber }}
            </div>
            <div class="mt-2">
                <v-btn color="error" text @click="unenroll()">Disable MFA</v-btn>
            </div>
        </div>
        <div v-else>
            <div>
                You currently do not have Multi-Factor Authentication enabled. You can enable it by entering your phone number below. After submitting, you will receive a code via SMS to verify your phone number. Standard messaging rates may apply.
            </div>

            <div v-if="!isAwaitingMfa">
                <v-text-field
                v-model="phoneNumber"
                class="mt-2"
                label="Phone Number for MFA"
                hide-details="auto"
                name="phoneNumber"
                outlined
                maxlength="10"
                type="tel"
                @input="formatPhoneNumber($event)"
                dense />

                <v-btn
                    @click="sendCode()"
                    :disabled="!phoneNumber || !rules.phoneNumber(phoneNumber)"
                    class="mt-1"
                    color="primary">
                    Send Verification Code
                </v-btn>
            </div>

            <div v-else>
                <v-text-field
                  v-model="smsCode"
                  class="mt-2"
                  label="Verification Code"
                  name="smsCode"
                  outlined
                  maxlength="6"
                  type="tel"
                  hide-details="auto"
                  keyboardType="number-pad"
                  dense />

                <v-btn
                    @click="submitNewMfa()"
                    class="mt-1"
                    :disabled="!smsCode"
                    color="primary">
                    Verify
                </v-btn>
            </div>

            <div v-if="submissionError" class="error--text mt-2">
                {{ submissionError }}
            </div>

        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('input', false)">Close</v-btn>
      </v-card-actions>
    </v-card>
    <reauthentication-dialog v-model="isReauthenticationDialogVisible" @reauthenticated="onReauthenticate()" />
    <div id="recaptcha-container-enroll"></div>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier } from 'firebase/auth';
import ReauthenticationDialog from './ReauthenticationDialog.vue';

export default {
  components: { ReauthenticationDialog },
  name: 'ManageMfaDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mfaFactors: [],
      isReauthenticationDialogVisible: false,
      phoneNumber: '',
      rules: {
        required: (value) => Boolean(value) || 'Required.',
        phoneNumber: (value) => {
          const phoneNumberRegex = /^\d{10}$/;
          return phoneNumberRegex.test(value) || 'Invalid phone number.';
        },
      },
      smsCode: null,
      isAwaitingMfa: false,
      recaptchaVerifier: null,
      verificationId: null,
      submissionError: null,
      hasSentEmailVerification: false,
      isStillNotEmailVerified: false,
    };
  },
  computed: {
    currentEmail () {
      const user = this.auth.currentUser
      return user && user.email
    },
    isEmailVerified () {
      const user = this.auth.currentUser
      return user && user.emailVerified
    },
  },
  methods: {
    ...mapActions('app', [
      'showSuccess', 
    ]),
    async fetchMfaFactors() {
      try {
        const user = this.auth.currentUser;
        if (user) {
          const userMultiFactor = multiFactor(user);
          this.mfaFactors = userMultiFactor.enrolledFactors;
        }
      } catch (error) {
        console.error('Error fetching MFA factors:', error);
      }
    },
    async unenroll() {
      try {
        const user = this.auth.currentUser;
        const userMultiFactor = multiFactor(user);
        if (userMultiFactor.enrolledFactors.length) {
          await userMultiFactor.unenroll(userMultiFactor.enrolledFactors[0]);
          this.mfaFactors = [];
          this.showSuccess('Multi-Factor Authentication disabled successfully')
          this.fetchMfaFactors();
        }
      } catch (error) {
        if (error.code === 'auth/requires-recent-login') {
          this.isReauthenticationDialogVisible = true;
        } else {
          console.error(error)
        }
      }
    },
    formatPhoneNumber(str) {
      const phoneNumber = str.replace(/\D/g, '');
      this.phoneNumber = phoneNumber;
    },
    async sendCode () {
      try {
        const multiFactorUser = multiFactor(this.auth.currentUser)
        const multiFactorSession = await multiFactorUser.getSession();
        const phoneAuthProvider = new PhoneAuthProvider(this.auth);

        const phoneInfoOptions = {
          phoneNumber: `+1${this.phoneNumber}`,
          session: multiFactorSession,
        }

        if (!this.recaptchaVerifier) {
          this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-enroll', {
            size: 'invisible',
          }, this.auth);
        }

        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          this.recaptchaVerifier,
        );
        this.isAwaitingMfa = true;
      } catch (error) {
        if (error.code === 'auth/requires-recent-login') {
          this.isReauthenticationDialogVisible = true;
        } else {
          this.isAwaitingMfa = false;
          this.showSuccess('Error sending verification code')
          console.error('Error sending verification code:', error);
        }
      }
    },
    submitNewMfa () {
      const phoneAuthCredential = PhoneAuthProvider.credential(this.verificationId, this.smsCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
      multiFactor(this.auth.currentUser).enroll(multiFactorAssertion)
        .then(() => {
          this.showSuccess('Multi-Factor Authentication enabled successfully');
          this.fetchMfaFactors();
          this.isAwaitingMfa = false;
          this.phoneNumber = '';
          this.smsCode = '';
        })
        .catch((error) => {
          if (error.code === 'auth/credential-already-in-use') {
            this.submissionError = 'This phone number is already in use for Multi-Factor Authentication. Please use a different phone number.';
            this.isAwaitingMfa = false;
          } else if (error.code === 'auth/invalid-verification-code') {
            this.submissionError = 'Invalid verification code. Please try again.';
          } else {
            this.submissionError = 'Error enabling Multi-Factor Authentication. Please try again.';
            this.isAwaitingMfa = false;
          }
        })
    },
    onReauthenticate () {
      if (this.isAwaitingMfa) {
        this.submitNewMfa();
      } else {
        this.unenroll();
      }

      this.isReauthenticationDialogVisible = false;
    },
    async reloadAuthState() {
      this.isStillNotEmailVerified = false;
      await this.auth.currentUser.reload();
      if (!this.auth.currentUser.emailVerified) {
        this.isStillNotEmailVerified = true;
      } else {
        this.fetchMfaFactors();
      }
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.isEmailVerified) {
          this.fetchMfaFactors();
        }
        this.phoneNumber = '';
        this.smsCode = '';
        this.isAwaitingMfa = false;
        this.submissionError = null;
      }
    },
  },
};
</script>

<style scoped>
</style>
