<template>
  <v-alert class="mt-2 mx-2 primary white--text" v-if="schooldogUpdateMinor" v-model="showAlert" dark dismissible>
    There are updates available. Please reload to get the latest features.
    <a class="white--text text-decoration-underline" @click="reload">Click to reload</a>
  </v-alert>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      showAlert: true,
    };
  },
  watch: {
    showAlert() {
      if (!this.showAlert) {
        setTimeout(() => {
          this.showAlert = true;
        }, 7200000); // user can suppress the alert for two hours (60 * 60 * 2 * 1000 = 7200000 ms)
      }
    },
  },
  computed: {
    ...mapState('app', [
      'schooldogUpdateMinor',
    ]),
  },
  methods: {
    reload() {
      window.location.reload();
    },
  },
}
</script>
