import { appConfig } from '../app-config';

function throttle(fn, wait) {
  let lastTime = 0;
  return function(...args) {
    const now = Date.now();
    if (now - lastTime >= wait) {
      lastTime = now;
      return fn.apply(this, args);
    }
  };
}

export default {
  data() {
    return {
      activityCheckInterval: null,
      inactivityDuration: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
      handleUserActivity: null,
    };
  },
  mounted() {
    if (!appConfig.enableAutologout) {
      return
    }

    // Initialize last activity from localStorage if available
    const storedLastActivity = localStorage.getItem('lastActivity');
    if (!storedLastActivity && this.$store.state.app.user) {
      const lastActivity = Date.now();
      localStorage.setItem('lastActivity', lastActivity.toString());
    }

    // Create a throttled version of the user activity handler
    this.handleUserActivity = throttle(this.setLastActivity, 5000); // Throttle every 5 seconds

    // Add event listeners for user activity
    window.addEventListener('mousemove', this.handleUserActivity);
    window.addEventListener('keydown', this.handleUserActivity);
    window.addEventListener('scroll', this.handleUserActivity);
    window.addEventListener('click', this.handleUserActivity);

    // Check activity every 30 seconds
    this.activityCheckInterval = setInterval(this.checkActivity, 30000);
  },
  beforeDestroy() {
    // Remove event listeners when component is destroyed
    window.removeEventListener('mousemove', this.handleUserActivity);
    window.removeEventListener('keydown', this.handleUserActivity);
    window.removeEventListener('scroll', this.handleUserActivity);
    window.removeEventListener('click', this.handleUserActivity);

    // Clear the inactivity timeout and interval
    clearInterval(this.activityCheckInterval);
  },
  methods: {
    setLastActivity() {
      if (this.$store.state.app.user) {
        const lastActivity = Date.now();
        localStorage.setItem('lastActivity', lastActivity.toString());
      }
    },
    checkActivity() {
      const now = Date.now();
      const rawLastActivity = localStorage.getItem('lastActivity');
      const lastActivity = rawLastActivity ? parseInt(rawLastActivity, 10) : false;

      if (!lastActivity || (now - lastActivity > this.inactivityDuration)) {
        if (this.$store.state.app.user) {
          this.logOut();
        }
      }
    },
    logOut() {
      localStorage.removeItem('lastActivity')
      this.$store.dispatch('app/logOut')
      this.$store.commit('app/showToast', {
        message: 'You were automatically signed out due to inactivity',
      })
    },
  },
};
