<template>
<div v-if="walk" class="mb-2">
    <div class="text-h5 mb-1">
      {{ walk.timeCreated | formatDate('M/D/YY') }} SchoolDog Walk for {{ school.name }}
    </div>
    <div class="text-subtitle-1 text--secondary">
      This SchoolDog Walk is <b>{{ statusLabels[walk.status] }}</b>.
      <span v-if="'complete' === walk.status">
        You can no longer make any changes, but you can still access the data.
      </span>
      <span v-else-if="'review' === walk.status">
        It was submitted to <b>{{assignedTo}}</b> for review by <b>{{leader}}</b>. If no revisions are requested within 72 hours of submission, the SchoolDog Walk will automatically complete.
      </span>
    </div>
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Walk from '@/models/Walk'
export default {
  name: 'Walkheader',
  props: {
    walk: Walk,
  },
  data() {
    return {
      isDeleteWalkDialogVisible: false,
      isSubmitting: false,
      statusLabels: {
        review: 'In Review',
        inprogress: 'In Progress',
        complete: 'Complete',
      },
    };
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    ...mapState('app', [
      'usersById',
    ]),
    assignedTo () {
      if ('review' === this.walk.status) {
        return this.usersById[this.walk.awaitingReviewBy] ? `${this.usersById[this.walk.awaitingReviewBy].firstName} ${this.usersById[this.walk.awaitingReviewBy].lastName}` : 'Unknown'
      }
      return null
    },
    leader () {
      return this.usersById[this.walk.leaderUserId] ? `${this.usersById[this.walk.leaderUserId].firstName} ${this.usersById[this.walk.leaderUserId].lastName}` : 'Unknown'
    },
    school () {
      return this.getSchoolById(this.walk.schoolId) || {}
    },
  },
};
</script>
<style lang="scss">
</style>