<template>
<div class="combobox-container" :class="name">
  <v-combobox
    v-on="$listeners"
    v-bind="$attrs"
    :attach="
      $vuetify.breakpoint.smAndUp ? false : `.${name}`
    "
    :items="items"
    ref="combobox"
    class="mt-1"
    hide-details
    label="Location"
    outlined
    dense
  ></v-combobox>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    name: String,
    schoolId: String,
    headerText: {
      type: String,
      default: null,
    },
    minOccurrences: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapState('app', [
      'schoolLocationsById',
    ]),
    items () {
      if (this.headerText) {
        return [
          {
            header: this.headerText,
          },
          ...this.mappedSchoolLocations,
        ]
      }
      return [
        ...this.mappedSchoolLocations,
      ]
    },
    mappedSchoolLocations() {
      const schoolLocations = this.schoolLocationsById[this.schoolId];
      if (!schoolLocations || !schoolLocations.usageMap) {
        return [];
      }
      const keys = Object.keys(schoolLocations.usageMap).filter(key => schoolLocations.usageMap[key] >= this.minOccurrences);
      return keys.sort((a, b) => {
        return (
          schoolLocations.usageMap[b] - schoolLocations.usageMap[a]
        );
      });
    },
  },
  methods: {
    // Called by parent
    blur () {
      this.$refs.combobox.blur();
    },
  },
}
</script>