<template>
  <div v-if="walk && !isLoading" style="display: flex; width: 100%;">
    <div style="flex: 1;">
      <v-toolbar
        v-if="isReviewing"
        color="#ffffff"
        style="left: 0; border-bottom: 1px solid #1b7476;"
        width="100%">
        <div class="progress-nav-bar">
          <div
            :class="{ 'active-step': currentTab === 1 }"
            class="step-container"
            style="flex: 0"
          >
            <div
              @click="currentTab = 1"
              :class="['step-circle', getCircleClass(1)]"
            >
              <template v-if="currentTab < 1">{{ 2 }}</template>
              <template v-else-if="currentTab === 1">
                <span class="number">{{ 1 }}</span>
              </template>
              <template v-else>
                <v-icon class="check-icon" color="white">{{ getCircleClass(1) === 'warning' ? 'mdi-alert' : 'mdi-check' }}</v-icon>
              </template>
            </div>
            <div @click="currentTab = 1" class="step-label text-body-1" :style="currentTab < 1 ? 'color: gray': ''">Observations</div>
          </div>
          <div
            class="step-container"
            :class="{ 'active-step': currentTab === 2 }"
          >
            <div class="step-line" :class="{ inactive: currentTab === 0}"></div>
            <div
              @click="currentTab = 2"
              :class="['step-circle', getCircleClass(2)]"
            >
              <template v-if="currentTab < 2">{{ 2 }}</template>
              <template v-else-if="currentTab === 2">
                <span class="number">{{ 2 }}</span>
              </template>
            </div>
            <div @click="currentTab = 2" class="step-label text-body-1" :style="currentTab < 2 ? 'color: gray': ''">Review</div>
          </div>
        </div>
        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.mdAndUp"
          @click.stop="isContextDrawerVisible = !isContextDrawerVisible"
          class="ml-2"></v-app-bar-nav-icon>
      </v-toolbar>
      <div class="px-3 py-2"  :style="$vuetify.breakpoint.mdAndUp ? `overflow-y: auto; height: calc(100vh - 64px${this.isReviewing ? ' - 64px' : ''})` : ''">
        <walk-header :walk="walk"></walk-header>
        <div v-show="1 === currentTab">
          <v-card class="mt-1 mb-2" v-if="!isReviewing && user.canAtSchool('download walk reports', walk.schoolId)">
            <v-card-title>
              Reports
            </v-card-title>
            <v-card-subtitle>
              Please ensure pop-up blockers are disabled if you are having trouble downloading reports.
            </v-card-subtitle>
            <v-card-text>
              <div>
                <v-btn @click="generateReport('summative')" color="primary" text>
                  <v-icon>mdi-file-pdf-box</v-icon><span style="padding: 12px">Full Summative Report</span>
                </v-btn>
              </div>
              <div>
                <v-btn @click="generateReport('observations')" color="primary" text>
                  <v-icon>mdi-file-pdf-box</v-icon><span style="padding: 12px">Observation Entries Report</span>
                </v-btn>
              </div>
              <div>
                <v-btn @click="generateCsvForWalk()" color="primary" text>
                  <v-icon>mdi-file-excel-box</v-icon><span style="padding: 12px">Observation Entry Data CSV</span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
          <v-divider v-if="!isReviewing && user.canAtSchool('download walk reports', walk.schoolId)" class="my-2"></v-divider>
          <v-card class="mt-1 mb-2">
            <v-card-title>
              Observation Entries
            </v-card-title>
            <v-card-subtitle>
              Filter visible entries
            </v-card-subtitle>
            <v-card-text>
              <div>
                <b>Observation Types</b>
              </div>
              <div class="d-flex">
                <a @click="isShowingConcerns = true; isShowingPraises = true; isShowingOther = true;" class="text-decoration-underline">Select All</a>
                <span class="mx-1">|</span>
                <a @click="isShowingConcerns = false; isShowingPraises = false; isShowingOther = false;" class="text-decoration-underline">Clear All</a>
              </div>
              <v-checkbox
                v-model="isShowingConcerns"
                dense
                hide-details
                :label="`Concerns (${numberOfConcerns})`">
              </v-checkbox>
              <v-checkbox
                v-model="isShowingPraises"
                dense
                hide-details
                :label="`Praises (${numberOfPraises})`">
              </v-checkbox>
              <v-checkbox
                v-model="isShowingOther"
                dense
                hide-details
                :label="`Other (${numberOfOther})`">
              </v-checkbox>
              <div class="mt-2">
                <b>Observation Categories</b>
              </div>
              <div class="d-flex">
                <a @click="observationCategoryFilters = Array.from(Object.keys(observationCategoriesForWalkCount))" class="text-decoration-underline">Select All</a>
                <span class="mx-1">|</span>
                <a @click="observationCategoryFilters = []" class="text-decoration-underline">Clear All</a>
              </div>
              <v-checkbox
                v-for="categoryId in Array.from(Object.keys(observationCategoriesForWalkCount))"
                v-model="observationCategoryFilters"
                dense
                hide-details
                :key="categoryId"
                :label="`${(observationCategoryById[categoryId] || {label:'N/A'}).label} (${observationCategoriesForWalkCount[categoryId]})`"
                :value="categoryId">
              </v-checkbox>
            </v-card-text>
          </v-card>
          <answer-rubric
            v-for="rubric in walk.rubrics.filter(r => r && !r.skip) || []"
            :key="rubric.id"
            :rubric="rubric"
            :allObservations="walkObservations"
            readonly
          />
          <div class="row pa-2 pt-0">
            <div
              v-for="(observation, index) in filteredObservations"
              :class="{
                'col-12': !$vuetify.breakpoint.mdAndUp,
                'col-6': $vuetify.breakpoint.mdAndUp,
              }"
              :key="`${index}-observation`"
              style="padding: 8px"
            >
              <observation-card
                style="max-width: calc(100vw - 48px); height: 100%;"
                :observation="observation"
                :observation-number="index + 1"
                :settings="settings"
                readonly>
              </observation-card>
            </div>
          </div>
          <div class="pa-0">
            <div class="row pa-2">

            </div>
          </div>
          <div
            v-if="isReviewing"
            class="row justify-center">
            <v-btn
              @click="currentTab = 2"
              color="primary"
              large
              outlined
            >
              Continue
            </v-btn>
          </div>
        </div>

        <div v-show="2 === currentTab">
          <v-card class="mt-1 mb-2">
            <v-card-title>
              Everything look good?
            </v-card-title>
            <v-card-subtitle>
              Once a SchoolDog Walk is approved, it cannot be edited.
            </v-card-subtitle>
            <v-card-text>
              <v-radio-group v-model="isApprovingWalk" class="mt-1 mb-2" dense hide-details>
                <template v-slot:label>
                  <div><b>Is the data in this SchoolDog Walk accurate to the best of your knowledge?</b></div>
                </template>
                <v-radio label="Yes. By submitting, I approve the completion of the SchoolDog Walk." :value="true"></v-radio>
                <v-radio label="No. By submitting, I will request the revisions specified below." :value="false"></v-radio>
                <v-form v-if="isApprovingWalk === false" v-model="isCommentsFormValid" ref="commentsForm">
                  <v-textarea
                    v-model="comments"
                    :rows="3"
                    :rules="[
                      val => isApprovingWalk || Boolean(val) || 'You must provide Review Comments if you are requesting revisions'
                    ]"
                    auto-grow
                    class="mt-1"
                    hide-details="auto"
                    label="Review Comments"
                    outlined
                    dense
                  ></v-textarea>
                </v-form>
              </v-radio-group>
            </v-card-text>
          </v-card>
          <div class="row justify-center">
            <v-btn
              @click="submitReview()"
              :disabled="isSubmitting || (null === isApprovingWalk)"
              color="primary"
              x-large
            >
              Submit
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="isContextDrawerVisible"
      class="elevation-1 py-0"
      floating
      permanent
      right
      width="360px"
      :style="!(isContextDrawerVisible && $vuetify.breakpoint.mdAndUp) ? 'width: 0' : ''"
    >
      <div>
        <div>
          <div style="display: flex; justify-content: center; align-items: center;">
            <div style="flex: 1">
              <v-card-title>
                Context
              </v-card-title>
              <v-card-subtitle v-if="isReviewing">
                Review the below data for accuracy.
              </v-card-subtitle>
            </div>
          </div>
        </div>
        <v-divider color="primary"></v-divider>
        <div class="px-2 pt-2" style="overflow-y: scroll; height: calc(100vh - 64px - 87px)">
          <v-card class="mb-2">
            <v-container>
              <div class="text-subtitle-1 mb-1">
                Weather
              </div>
              <div class="text-body-2 mb-1">
                <div class="font-weight-thin text-caption">
                  Precipitation
                </div>
                <div class="font-weight-medium">
                  {{walk.context.weatherPrecipitation || 'N/A'}}
                </div>
              </div>
              <div class="text-body-2 mb-1">
                <div class="font-weight-thin text-caption">
                  Temperature
                </div>
                <div class="font-weight-medium">
                  {{walk.context.weatherTemperature || 'N/A'}}
                </div>
              </div>
              <div class="text-body-2">
                <div class="font-weight-thin text-caption">
                  Comment
                </div>
                <div class="font-weight-medium">
                  {{walk.context.weatherComment || 'N/A'}}
                </div>
              </div>
            </v-container>
          </v-card>
          <v-card class="mb-2">
            <v-container>
              <div class="text-subtitle-1 mb-1">
                Additional Notes
              </div>
              <div class="text-body-2">
                <div class="font-weight-medium">
                  {{walk.context.additionalNotes || 'N/A'}}
                </div>
              </div>
            </v-container>
          </v-card>
          <walk-history-card class="mb-2" :walk="walk"></walk-history-card>
        </div>
      </div>
    </v-navigation-drawer>

    <v-fab-transition v-if="!$vuetify.breakpoint.mdAndUp">
      <v-btn
        id="review-walk-view-context"
        @click="isContextDialogVisible = true"
        fixed
        color="grey darken-1"
        dark
        elevation="12"
        bottom
        left
        rounded
        x-large
      >
        View Context
      </v-btn>
    </v-fab-transition>

    <!-- Dialogs -->
    <v-dialog v-model="isContextDialogVisible" max-width="528px">
      <v-card>
        <div>
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div>
              <v-card-title>
                Context
              </v-card-title>
              <v-card-subtitle v-if="isReviewing">
                Review the below data for accuracy.
              </v-card-subtitle>
            </div>
            <v-btn @click="isContextDialogVisible = false" class="mx-1" icon>
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <v-divider color="primary"></v-divider>
          <div class="px-2 pt-2" style="overflow-y: scroll; height: calc(100vh - 64px - 87px)">
            <v-card class="mb-2">
              <v-container>
                <div class="text-subtitle-1 mb-1">
                  Weather
                </div>
                <div class="text-body-2 mb-1">
                  <div class="font-weight-thin text-caption">
                    Precipitation
                  </div>
                  <div class="font-weight-medium">
                    {{walk.context.weatherPrecipitation || 'N/A'}}
                  </div>
                </div>
                <div class="text-body-2 mb-1">
                  <div class="font-weight-thin text-caption">
                    Temperature
                  </div>
                  <div class="font-weight-medium">
                    {{walk.context.weatherTemperature || 'N/A'}}
                  </div>
                </div>
                <div class="text-body-2">
                  <div class="font-weight-thin text-caption">
                    Comment
                  </div>
                  <div class="font-weight-medium">
                    {{walk.context.weatherComment || 'N/A'}}
                  </div>
                </div>
              </v-container>
            </v-card>
            <v-card class="mb-2">
              <v-container>
                <div class="text-subtitle-1 mb-1">
                  Additional Notes
                </div>
                <div class="text-body-2">
                  <div class="font-weight-medium">
                    {{walk.context.additionalNotes || 'N/A'}}
                  </div>
                </div>
              </v-container>
            </v-card>
            <walk-history-card class="mb-4" :walk="walk"></walk-history-card>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isInstructionDialogVisible" max-width="528px">
      <v-card>
        <v-card-title>
          Review SchoolDog Walk
        </v-card-title>
        <v-card-text>
          <span v-if="walk.leaderUserId && submittedForReviewDate">This SchoolDog Walk was led by <user-link :user-id="walk.leaderUserId" /> submitted for review on {{submittedForReviewDate}}.</span> Please take a moment to review the context and observations for accuracy. At the end, you can choose to request revisions. If you do not request revisions within 72 hours of being assigned this review, the SchoolDog Walk will automatically complete.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isInstructionDialogVisible = false" color="primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isSubmitting" persistent max-width="528px">
      <v-card>
        <v-card-title>
          Submitting...
        </v-card-title>
        <v-card-text>
          <div class="row justify-center py-4">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isGeneratingReport" persistent max-width="528px">
      <v-card>
        <v-card-title>
          Generating Report...
        </v-card-title>
        <v-card-text>
          <div>
            This might take a few seconds.
          </div>
          <div class="row justify-center pt-4">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isDownloadedReportDialogVisible" max-width="528px">
      <v-card>
        <v-card-title>
          Your Report Is Ready
        </v-card-title>
        <v-card-text>
          <div>
            Your generated report should have opened automatically. You can also use the link below to download. The link is only valid for 10 minutes after generation.
          </div>
          <div class="row justify-center pt-4">
            <v-btn text :href="reportUrl" target="_blank" color="primary">
              <v-icon>mdi-download</v-icon><span style="padding: 12px">Download Generated Report</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isDownloadedReportDialogVisible = false" color="primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isDownloadedCsvDialogVisible" max-width="528px">
      <v-card>
        <v-card-title>
          Your CSV Is Ready
        </v-card-title>
        <v-card-text>
          <div>
            Your generated CSV should have downloaded automatically. You can also use the link below to download.
          </div>
          <div class="row justify-center pt-4">
            <v-btn text @click="finishCsvDownload()" color="primary">
              <v-icon>mdi-download</v-icon><span style="padding: 12px">Download Generated CSV</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isDownloadedCsvDialogVisible = false" color="primary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { downloadCsv } from '@/services/DownloadCsvService'
import Walk from '@/models/Walk'
import Settings from '@/models/Settings'
import ObservationCard from '../../components/walks/ObservationCard.vue';
import WalkHistoryCard from '../../components/walks/WalkHistoryCard.vue';
import WalkHeader from '../../components/walks/WalkHeader.vue';
import AnswerRubric from '../../components/rubric/AnswerRubric.vue';
import UserLink from '../../components/common/UserLink.vue';

export default {
  components: {
    ObservationCard,
    WalkHistoryCard,
    WalkHeader,
    AnswerRubric,
    UserLink,
  },
  name: 'ReviewWalkPage',
  props: {
    isReviewing: Boolean,
  },
  data() {
    return {
      comments: null,
      currentTab: 1,
      isApprovingWalk: null,
      isCommentsFormValid: false,
      isContextDrawerVisible: false,
      isContextDialogVisible: false,
      isDownloadedReportDialogVisible: false,
      isDownloadedCsvDialogVisible: false,
      isGeneratingReport: false,
      isInstructionDialogVisible: this.isReviewing,
      isLoadingWalk: false,
      isLoadingsettings: false,
      isShowingConcerns: true,
      isShowingOther: true,
      isShowingPraises: true,
      isSubmitting: false,
      observationCategoryFilters: [],
      reportUrl: null,
      settings: null,
      walk: null,
    };
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    ...mapState('app', [
      'user',
      'users',
    ]),
    expectedStatus () {
      if (this.isReviewing) {
        return 'review';
      }
      return 'complete';
    },
    analysisScores() {
      return this?.walk?.analysis?.scores ?? {};
    },
    analysisList() {
      const scoresListed = [];
      this.observationCategories.forEach((category) => {
        scoresListed.push({
          id: category.id,
          score: this.analysisScores[category.id] ?? 0,
          name: category.label,
        });
      });
      return scoresListed.sort((a, b) => {
        a.name > b.name
      });
    },
    submittedForReviewDate() {
      const history = this.walk.submissionHistory ?? [];
      const submissionsForReview = history.filter((walk) => walk.fromStatus != 'inprogress');
      if (submissionsForReview.length == 0) {
        return null;
      }
      const submissionsSorted = submissionsForReview.sort((histA, histB) => Date.parse(histB.timestamp) - Date.parse(histA.timestamp));
      return (new Date(Date.parse(submissionsSorted[0].timestamp))).toLocaleDateString();
    },
    filteredObservations () {
      return this.walkObservations.filter((observation) => {
        if (!this.isShowingConcerns && 'concern' === observation.observationType) {
          return false;
        }
        if (!this.isShowingPraises && 'praise' === observation.observationType) {
          return false;
        }
        if (!this.isShowingOther && 'other' === observation.observationType) {
          return false;
        }
        if (!this.observationCategoryFilters.includes(observation.observationCategoryId)) {
          return false;
        }
        return true;
      })
    },
    numberOfConcerns () {
      return this.walkObservations.filter(observation => 'concern' === observation.observationType).length
    },
    numberOfOther () {
      return this.walkObservations.filter(observation => 'other' === observation.observationType).length
    },
    numberOfPraises () {
      return this.walkObservations.filter(observation => 'praise' === observation.observationType).length
    },
    observationCategoryById() {
      const map = {};
      this.observationCategories.forEach((observationCategory) => {
        map[observationCategory.id] = observationCategory;
      });
      return map;
    },
    observationCategories() {
      if (this.settings) {
        return this.settings.categories;
      }
      return [];
    },
    observationCategoriesForWalkCount() {
      const map = {}
      this.walkObservations.forEach((observation) => {
        if (!map[observation.observationCategoryId]) {
          map[observation.observationCategoryId] = 0;
        }
        map[observation.observationCategoryId]++;
      })
      return map;
    },
    observationItems() {
      if (this.settings) {
        // TODO: check if this should be `items`?
        return this.settings.itmes;
      }
      return [];
    },
    observationItemById() {
      const map = {};
      this.observationItems.forEach((observationItem) => {
        map[observationItem.id] = observationItem;
      });
      return map;
    },
    observationItemsByCategory() {
      const map = {};
      if (!this.settings) {
        return map
      }
      this.settings.items.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          if (!map[categoryId]) {
            map[categoryId] = []
          }
          map[categoryId].push(observationItem)
        })
      })
      return map;
    },
    isLoading() {
      return (
        this.isLoadingWalk || this.isLoadingsettings
      );
    },
    school () {
      return this.getSchoolById(this.walk.schoolId)
    },
    walkObservations () {
      const observations = Object.values(this.walk.observationsById || {}).filter(observation => !observation.isDeleted)
      observations.sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp)
      })
      return observations
    },
  },
  methods: {
    ...mapActions({
      showError: 'app/showError',
      showSuccess: 'app/showSuccess',
    }),
    async submitReview() {
      this.isSubmitting = true;

      if (this.$refs.commentsForm) {
        this.$refs.commentsForm.validate();
        if (!this.isCommentsFormValid) {
          this.isSubmitting = false;
          return;
        }
      }

      try {
        await this.runFunction('submitReview', {
          walkId: this.walk.id,
          isApproved: this.isApprovingWalk,
          comments: this.$refs.commentsForm ? this.comments : null,
        })
        if (this.isApprovingWalk) {
          this.$router.replace(`/walks/walk/${this.walk.id}/complete`);
          location.reload();
          this.showSuccess('SchoolDog Walk completed successfully')
        } else {
          this.showSuccess('Revisions Requested');
          this.$router.replace('/walks');
        }
      } finally {
        this.isSubmitting = false
      }
    },
    async generateReport(reportType) {
      this.isGeneratingReport = true;

      try {
        const data = await this.runFunction('generateReport', {
          reportType,
          walkId: this.walk.id,
        })
        this.reportUrl = data
        window.open(data, '_blank');

        this.isDownloadedReportDialogVisible = true
      } finally {
        this.isGeneratingReport = false
      }
    },
    async generateCsvForWalk() {
      this.isGeneratingReport = true;
      try {
        const data = await this.runFunction('generateCsvForWalk', {
          walkId: this.walk.id,
        })
        this.reportCsvData = data.csv
        this.finishCsvDownload()
        this.isDownloadedCsvDialogVisible = true
      } finally {
        this.isGeneratingReport = false
      }
    },
    async finishCsvDownload() {
      const walkDate = this.$options.filters.formatDate(this.walk.timeCreated, 'MM-DD-YYYY')
      const name = `${this.school.name} - ${walkDate} SchoolDog Walk - Observation Entries`
      downloadCsv(this.reportCsvData, name)
    },
    getCircleClass(stepNumber) {
      if (this.currentTab < stepNumber) return 'inactive';
      else if (this.currentTab === stepNumber) return 'active';
      else if (stepNumber === 0 && (!this.walk.context.weatherTemperature || !this.walk.context.weatherPrecipitation)) return 'warning'
      else return 'completed';
    },
    async loadWalk() {
      this.isLoadingWalk = true;

      this.walk = await Walk.getById(this.$route.params.walkId)
      if (!this.walk || this.walk.status !== this.expectedStatus) {
        this.$router.replace('/walks');
      }

      this.observationCategoryFilters = Array.from(Object.keys(this.observationCategoriesForWalkCount));

      this.isLoadingWalk = false;
    },
    async loadsettings() {
      this.isLoadingsettings = true;

      this.settings = await Settings.getById('observation')
      if (!this.settings) {
        this.$router.replace('/walks');
      }

      this.isLoadingsettings = false;
    },
  },
  mounted() {
    this.loadWalk();
    this.loadsettings();
  },
};
</script>

<style lang="scss" scoped>
.progress-nav-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.step-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.step-circle {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--v-primary-base);
  color: var(--v-primary-base);
  transition: background-color 0.3s;
}

.step-label {
  cursor: pointer;
  margin-left: 4px;
  color: var(--v-primary-base);
  font-weight: 500;
}

/* When current step is the active step */
.step-circle.active {
  background-color: var(--v-primary-base);
  color: white;
}

.step-circle.warning {
  background-color: var(--v-warning-base);
  color: white;
}

.step-circle.inactive {
  border: 2px solid gray;
  color: gray;
}

/* When the step is completed */
.step-circle.completed {
  background-color: var(--v-primary-base);
  color: white;
}

.check-icon {
  font-size: 18px;
}

.number {
  font-weight: bold;
}

.step-line {
  flex: 1;
  height: 2px;
  background: var(--v-primary-base);
  margin: 0 8px;
}


.step-line.inactive {
  background: gray;
}

.full-height-with-header {
  min-height: calc(100vh - 64px);
  height: 100%;
}

::v-deep .v-navigation-drawer__content {
  padding: 0;
}

#review-walk-view-context {
  z-index: 100;
}
</style>
