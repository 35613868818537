export function downloadCsv(csvContent, name) {
  // Create a Blob from the CSV content
  const blob = new Blob([
    csvContent,
  ], { type: 'text/csv' });

  // Create a link element to download the CSV file
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${name}.csv`;

  // Append the link to the document body and trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
}