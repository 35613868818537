import Rubric from '../../models/Rubric';

function schools (state) {
  if (!state.organizationSettings || !state.organizationSettings.schools) {
    return []
  }
  return state.organizationSettings.schools.map((school) => {
    const schoolInfo = state.schoolInfos.find(
      (info) => info.id === school.id,
    );
    // Merge the school data with matching school info
    return {
      ...school,
      ...schoolInfo,
    };
  });
}

function globalAppSettings (state) {
  if (!state.globalAppSettings) {
    return {maintenanceMode: false}
  }
  return state.globalAppSettings
}

function getSchoolById (state) {
  return (id) => {
    return schools(state).find((school) => school.id === id) || null;
  }
}

function newRubricTemplate (state) {
  return new Rubric(null, {
    name: '',
    sections: [
      {
        showNotes: true,
        questions: [],
      },
    ],
    enabled: false,
    districtWalksOnly: false,
    skipByDefault: true,
    isDeleted: false,
  });
}

function getRubricById (state) {
  return (id) => {
    return state.rubrics.find((rubric) => rubric.id === id) || null;
  }
}

function getEligibleTaskAssigneesForTask (state) {
  return (task={}) => {
    return state.users.filter((user) => {
      return user.canAtSchool('be proposed tasks', task.schoolId)
    }).map(user => ({
      text: `${user.firstName} ${user.lastName} (${user.email})`,
      value: user.id,
    })).sort((a, b) => a.text.localeCompare(b.text));
  }
}

function getEligibleCollaboratorsForWalk (state) {
  return (walk) => {
    return state.users.filter((collaborator) => {
      return collaborator.canAtSchool('collaborate on walks', walk.schoolId)
    }).map((user) => ({
      text: `${user.firstName} ${user.lastName}${user.title ? ` - ${user.title} ` : ''} (${user.email})`,
      value: user.id,
    }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }
}

export default {
  getEligibleTaskAssigneesForTask,
  getEligibleCollaboratorsForWalk,
  getRubricById,
  getSchoolById,
  globalAppSettings,
  newRubricTemplate,
  schools,
}
