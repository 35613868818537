import FirestoreModel from './FirestoreModel';

class Rubric extends FirestoreModel {
    static collectionName = 'rubrics';
    static allowedFields = [
      'name',
      'enabled',
      'districtWalksOnly',
      'skipByDefault',
      'timeCreated',
      'createdByUserId',
      'timeUpdated',
      'updatedByUserId',
      'timeDeleted',
      'deletedByUserId',
      'isDeleted',
      'sections',
      'useScoreThresholds',
      'scoreThresholds',
    ]

    constructor(id, data) {
      super(id, data);
      if (!this.scoreThresholds) {
        this.useScoreThresholds = false;
        this.scoreThresholds = {};
        this.save()
      }
    }
}

export default Rubric;
